import React, {FunctionComponent} from 'react';

import GritxButton from 'components/gritx-button';
import {ButtonVariant} from 'components/gritx-button/ButtonVariantEnum';
import Loader from 'components/loader';
import XpeditionOverlay from 'components/xpedition-overlay';

import {BotActionType, IBotAction, IBotAnswer, MessageType} from 'utils/hooks/use-chat-bot/interfaces';
import {BotAnswer} from './botAnswer';

import './styles.scss';

interface IBotChat {
  groupAnswers: IBotAnswer[]
  singleAnswers: BotAnswer[]
  onClickAction: (actionButton: IBotAction) => void
  onChangeOverlayAnswer: (answer: IBotAction | null, autoSend?: boolean) => void
}

export const BotMessages: FunctionComponent<IBotChat> = ({
  groupAnswers,
  singleAnswers,
  onClickAction,
  onChangeOverlayAnswer
}: IBotChat) => {
  function handleClickAction(actionButton: IBotAction) {
    return () => {
      onClickAction(actionButton);
    };
  }

  function handleChangeOverlayAnswer(answer: IBotAction | null, autoSend?: boolean) {
    onChangeOverlayAnswer(answer, autoSend);
  }

  function renderBotAnswerButtons(answer: BotAnswer) {
    const buttons = answer.getButtons();

    return buttons && buttons.length ? <div className="bot-chat__message-buttons">
      {
        buttons.map((button, i) => {
          if (button.actionType === BotActionType.Overlay) {
            return <XpeditionOverlay
              key={i}
              answer={answer}
              onChange={handleChangeOverlayAnswer}
            />;
          }
          if (button.actionType === BotActionType.OpenURL) {
            return <a
              key={i}
              href={button.actionUrl}
              target="_blank"
              rel="noreferrer"
              className="bot-chat__chat-button--link"
              onClick={handleClickAction(button)}
            >
              {button.text}
            </a>;
          }

          return <GritxButton
            key={i}
            title={button.text as string}
            variant={ButtonVariant.Outline}
            className="bot-chat__chat-button"
            onClick={handleClickAction(button)}
          />;
        })}</div> : null;
  }

  function renderBotAnswerLinks(answer: BotAnswer) {
    return answer.getUrls().length
      ? answer.getUrls()
        .map(url => {
          return url.startsWith('data')
            ? <div key={url}>
              <a className="bot-chat__message-url" href={url}
                download={answer.getText()} target="_blank" rel="noreferrer">{answer.getText()}</a>
            </div>
            : <div key={url}>
              <a className="bot-chat__message-url" href={url} target="_blank" rel="noreferrer">{url}</a>
            </div>;
        })
      : null;
  }

  function renderBotAnswerText(answer: BotAnswer) {
    return answer.getType() !== MessageType.Url
      ? <span dangerouslySetInnerHTML={{
        __html: answer.getType() === MessageType.Url ? '' : answer.getText()
      }}/>
      : null;
  }

  function renderMessageCard(answers: IBotAnswer[]) {
    const picture = answers[0]?.picture?.fileUrl;
    const title = answers[1]?.text;
    const desc = answers[2]?.text;
    const buttons = groupAnswers[3]?.keyboard?.buttons || groupAnswers[2]?.keyboard?.buttons || [];

    return <>
      <div className="message-card">
        <div className="message-card__wrapper">
          <div className="message-card__title">
            {title}
          </div>
          <div className="message-card__content">
            {picture
              ? <img src={picture} role="presentation" alt="" className="message-card__picture"/>
              : <Loader className="message-card__picture message-card__picture-loader" nested/>}
            <div className="message-card__description">{desc}</div>
          </div>
        </div>
      </div>
      {buttons.length !== 0
      && <div className="bot-chat__message-buttons">
        {buttons.map((button, i) => {
          if (button && button.actionType !== 'OVERLAY') {
            return <GritxButton
              key={i}
              title={button?.text as string}
              variant={i ? ButtonVariant.Outline : ButtonVariant.Primary}
              className="bot-chat__chat-button bot-chat__group-button"
              onClick={handleClickAction(button)}
            />;
          }

          return null;
        })}
      </div>}
    </>;
  }

  return <>
    {
      singleAnswers.map((answer) => {
        if (answer.getContentId() && answer.getType() === MessageType.Picture) {
          return <>
            <div
              key={answer.getMessageId()}
              className="bot-chat__message bot-chat__message--left">
              <div className="bot-chat__message-value">
                {answer.getPictureUrl()
                  ? <img src={answer.getPictureUrl()} role="presentation" alt="" className="bot-chat__picture"/>
                  : <Loader className="bot-chat__picture-loader" nested/>}
              </div>
            </div>
          </>;
        }

        return answer.getType() !== MessageType.Picture && (answer.getText() || answer.getButtons()/* || answer.getUrls().length*/)
          ? <div key={answer.getMessageId()} className="bot-chat__message bot-chat__message--left">
            <div className="bot-chat__message-value">
              {renderBotAnswerText(answer)}
              {renderBotAnswerButtons(answer)}
              {/* {renderBotAnswerLinks(answer)}*/}
            </div>
          </div> : null;
      })
    }
    {groupAnswers.length !== 0
    && <div className="bot-chat__message bot-chat__message--left">
      <div className="bot-chat__message-value bot-chat__group">
        {renderMessageCard(groupAnswers)}
      </div>
    </div>
    }
  </>;
};
