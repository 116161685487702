import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';

import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {IStore} from 'redux/interface';
import {getBackpackContent, backpackAsyncActions, getTranslations} from 'redux/pageAsyncActions';

import NotFoundImg from 'assets/image/not-found/not-found-404.png';

import CardSection from 'components/card-section';
import Loader from 'components/loader';
import NotFoundInfo from 'components/not-found-info';

import './styles.scss';

export const Backpack: React.FunctionComponent = () => {
  const {
    translation: {
      locale,
      translations
    },
    content: {
      backpackContent
    },
    status: {
      loading
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const [isLoadCards, setIsLoadCards] = useState(true);

  useGetTranslations({
    locale,
    translations,
    dispatch,
    translationKey: 'backpack'
  });

  useEffect(() => {
    dispatch(backpackAsyncActions[getBackpackContent]());
    setIsLoadCards(false);
  }, []);

  function handleOpenCard(cardId: number) {
    history.push(
      `/content#${cardId}`,
      history.location.state ? history.location.state : {parentLocation: history.location.pathname}
    );
  }

  function renderFavorites() {
    return backpackContent.length === 0
      ? <>
        <NotFoundInfo
          title={intl.formatMessage({
            id: 'backpack.noFavorites',
            defaultMessage: 'Looks like you are all done for now! Congratulations! '
          })}
          image={NotFoundImg}
        />
      </>
      : <CardSection cards={backpackContent} onClickCard={handleOpenCard}/>;
  }

  return <div className={'backpack container'}>
    {
      !loading.includes(getTranslations) && <>
        <h1 className="backpack__title">
          <FormattedMessage
            id={'backpack.title'}
            defaultMessage={'Things to do and remember'}
          />
        </h1>
        <div className="backpack__border"/>
        <div className="backpack__cards">
          {
            loading.includes(getBackpackContent) || isLoadCards ? <Loader nested/> : renderFavorites()}
        </div>
      </>
    }

  </div>;
};
