import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';

import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input';
import cn from 'classnames';

import 'react-phone-number-input/style.css';
import './styles.scss';

interface IInputPhoneProps {
  labelClassName: string,
  inputPhoneValue: string,
  onChangeValue: (valueKey: string) => void,
  isPhoneChanged: (value: boolean) => void
}

export const InputPhone = ({
  labelClassName,
  inputPhoneValue,
  onChangeValue,
  isPhoneChanged
}: IInputPhoneProps): JSX.Element => {
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const validateNumber = (phoneValue: string) => phoneValue && isPossiblePhoneNumber(phoneValue);

  function showValidateMessage() {
    if (!inputPhoneValue) {
      setShowErrorMessage(false);
      isPhoneChanged(true);
    }

    if (inputPhoneValue && inputPhoneValue.length > 5 && !validateNumber(inputPhoneValue)) {
      setShowErrorMessage(true);
      isPhoneChanged(false);
    }

    if (validateNumber(inputPhoneValue)) {
      setShowSuccessMessage(true);
      isPhoneChanged(true);
    }
  }

  function hideValidateMessage() {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    isPhoneChanged(false);
  }

  return (
    <div className="phone">
      <label
        className={cn('phone__label', labelClassName)}
        htmlFor="PhoneInput"
      >
        <FormattedMessage
          id="user-settings.phone"
          defaultMessage="Phone"
        />
      </label>
      <PhoneInput
        className={cn('PhoneInput', {'PhoneInput--error': showErrorMessage})}
        value={inputPhoneValue}
        countryOptionsOrder={['US', 'SG', '|', '...']}
        addInternationalOption={false}
        limitMaxLength={true}
        international
        defaultCountry="US"
        onChange={onChangeValue}
        onFocus={hideValidateMessage}
        onBlur={showValidateMessage}
      />
      <div className="phone__message">
        {showErrorMessage
          ? <span className="phone__message--error">
            <FormattedMessage
              id="user-settings.phone.messageError"
              defaultMessage="The phone number is invalid"
            />
          </span>
          : null
        }
        {showSuccessMessage
          ? <span className="phone__message--success">
            <FormattedMessage
              id="user-settings.phone.messageSuccess"
              defaultMessage="Character number below limit"
            />
          </span>
          : null
        }
      </div>
    </div>
  );
};
