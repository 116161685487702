import {AxiosError} from 'axios';
import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {IStore} from './interface';
import {finishLoadingAction, setErrorAction} from './status/actions';

interface IErrorHandler {
  actionKey: string
  error: AxiosError,
  currentAction?: ThunkAction<void, IStore, unknown, Action>
}

export const errorHandler = ({
  actionKey,
  error,
  currentAction
}: IErrorHandler): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {auth0Client},
    status: {errors}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();

  function setError() {
    dispatch(setErrorAction({
      key: actionKey,
      value: error
    }));
    dispatch(finishLoadingAction(actionKey));
  }

  setError();

  if (error.isAxiosError) {
    if (error.response) {
      console.log('axios response error');
      // todo handle 401
      // if (error.response.status === 401 && isAuthenticated) {
      //   if (!errors[actionKey] || errors[actionKey]?.count <= 5) {
      //     setTimeout(() => {
      //       dispatch(currentAction);
      //     }, 1000);
      //   } else {
      //     auth0Client.logout();
      //   }
      // }
    }
    if (error.request) {
      console.log('axios request error');
    }
  } else {
    console.log('no axios error');
  }
});
