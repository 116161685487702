import React, {useEffect} from 'react';
import {FormattedMessage} from 'react-intl';

import './styles.scss';

interface ISnackBarItem {
  errorKey: string
  onClear: () => void
}

export const SnackBarItem: React.FunctionComponent<ISnackBarItem> = ({
  errorKey,
  onClear
}: ISnackBarItem) => {
  useEffect(() => {
    setTimeout(() => onClear(), 3000);
  });

  return <div className="snackbar__item">
    {errorKey}:
    <FormattedMessage
      id={'common.error.message'}
      defaultMessage={'Something went wrong'}
    />
    <button className="snackbar__button" onClick={onClear}>
      X
    </button>
  </div>;
};
