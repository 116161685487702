import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';

import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {IStore} from 'redux/interface';
import CrossroadImg from 'assets/image/library/skill-crossroad.svg';
import NotFoundInfo from 'components/not-found-info';
import './styles.scss';

export const Error: React.FunctionComponent = () => {
  const {
    translation: {
      locale,
      translations
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const intl = useIntl();

  useGetTranslations({
    locale,
    translations,
    dispatch,
    translationKey: 'error'
  });

  return <div className={'error-page'}>
    <NotFoundInfo
      title={intl.formatMessage({
        id: 'error.notFound',
        defaultMessage: 'Sorry, we can’t find the page you were looking for.'
      })}
      image={CrossroadImg}/>
  </div>;
};
