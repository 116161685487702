import React, {ReactElement, useEffect} from 'react';
import {IntlProvider} from 'react-intl';
import {Redirect, Route, RouteProps, Switch, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {IStore} from 'redux/interface';
import {getAuthorizationAction, startLoginAction} from 'redux/auth/authAsyncActions';

import Backpack from 'pages/backpack';
import Callback from 'pages/callback';
import Content from 'pages/content';
import Error from 'pages/error';
import Home from 'pages/home';
import Layout from 'components/layout';
import Library from 'pages/library';
import Materials from 'pages/materials';
import UserSettings from 'pages/user-settings';
import Xpedition from 'pages/xpedition';
import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import SnackBar from 'components/snackbar';
import {RouteVar} from 'utils/constants/constants';
import {Login} from '../login/Login';

const PrivateRoute: React.FunctionComponent<RouteProps> = ({
  children,
  ...rest
}) => {
  const auth = useSelector((state: IStore) => state.auth);
  const dispatch = useDispatch();

  return (
    <Route
      {...rest}
      render={
        () => {
          return auth.auth0User?.sub
            ? (<Layout>{children as ReactElement}</Layout>)
            : (<Login/>);
        }
      }
    />
  );
};

const App: React.FunctionComponent<RouteProps> = () => {
  const {
    translation: {
      locale,
      translations
    }
  } = useSelector((state: IStore) => state);

  const dispatch = useDispatch();
  const {pathname} = useLocation();

  useGetTranslations({
    locale,
    translations,
    dispatch,
    translationKey: 'common'
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
  }, [pathname]);

  useEffect(() => {
    dispatch(getAuthorizationAction());
  }, []);

  return (
    <IntlProvider locale={locale} messages={translations[locale]} onError={(e) => console.log('IntlError', e)}>
      <SnackBar/>
      <Switch>
        {/* <Route exact path={'/'}>*/}
        {/*  <Redirect to={RouteVar.Xpedition}/>*/}
        {/*  /!* <Layout hideSeparator={true}>*!/*/}
        {/*  /!*  <Home/>*!/*/}
        {/*  /!* </Layout>*!/*/}
        {/* </Route>*/}
        <Route exact path={'/callback'}>
          <Layout>
            <Callback/>
          </Layout>
        </Route>
        <PrivateRoute path={RouteVar.Library}>
          <Library/>
        </PrivateRoute>
        <PrivateRoute path={RouteVar.Materials}>
          <Materials/>
        </PrivateRoute>
        <PrivateRoute path={RouteVar.Backpack}>
          <Backpack/>
        </PrivateRoute>
        <PrivateRoute path={RouteVar.Xpedition}>
          <Xpedition/>
        </PrivateRoute>
        <PrivateRoute path={RouteVar.Settings}>
          <UserSettings/>
        </PrivateRoute>
        <PrivateRoute path={RouteVar.Content}>
          <Content/>
        </PrivateRoute>
        <Route path={'*'}>
          <Layout>
            <Error/>
          </Layout>
        </Route>
      </Switch>
    </IntlProvider>
  );
};

export default App;
