import axios, {AxiosResponse} from 'axios';
import {IProfileAvatar, IUserProfile} from '../auth/interfaces';

export const getUserProfileApi = (): Promise<AxiosResponse<IUserProfile>> => {
  return axios.get<IUserProfile>(`${process.env.REACT_APP_API_URL}/user/profile`);
};
export const getUserAvatarApi = (): Promise<AxiosResponse<IProfileAvatar>> => {
  return axios.get<IProfileAvatar>(`${process.env.REACT_APP_API_URL}/user/avatar`);
};
export const updateUserProfileApi = (profile: IUserProfile): Promise<AxiosResponse<IUserProfile>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/user/profile/update`,
    profile
  );
};
export const updateUserProfileAvatarApi = (fileId: number): Promise<AxiosResponse<{ value: string }>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/user/set/avatar`,
    fileId
  );
};
