import React, {FunctionComponent} from 'react';

import './styles.scss';
import {IOverlaySuggestion} from '../overlay/interfaces';

interface IBotAutocomplete {
  suggestions: IOverlaySuggestion[] | null
  handleChangeAutocompleteSuggestion: (value: IOverlaySuggestion) => void
  input: string
}

export const BotAutocomplete: FunctionComponent<IBotAutocomplete> = ({
  suggestions,
  handleChangeAutocompleteSuggestion,
  input
}: IBotAutocomplete) => {
  function handleOnclick(value: IOverlaySuggestion) {
    return () => {
      handleChangeAutocompleteSuggestion(value);
    };
  }

  return suggestions && suggestions.length ? <ul className="bot-chat__suggester">
    { suggestions.map(item => <li key={item.id} className="bot-chat__suggester-item">
      <button
        className="bot-chat__suggester-button"
        onClick={handleOnclick(item)}
        dangerouslySetInnerHTML={{
          __html: item.name.replace(input.toUpperCase(), `<b>${input.toUpperCase()}</b>`)
        }}
      />
    </li>)
    }
  </ul> : null;
};
