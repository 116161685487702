import React, {ChangeEvent, FunctionComponent} from 'react';
import {IOverlayInput} from 'components/overlay/interfaces';

import './styles.scss';
import {TextField} from '@mui/material';

interface IDCheckboxList {
  control: IOverlayInput
  onChange: (value: string) => void
  onPressEnter: () => void
}

export const DInput: FunctionComponent<IDCheckboxList> = ({
  control,
  onChange,
  onPressEnter
}: IDCheckboxList) => {
  function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
    onChange(e.target.value);
  }

  const keydownHandler = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onPressEnter();
    }
  };

  return <TextField size="small"
    className="d-input d-input__field"
    placeholder={control.placeholder}
    name={control.name}
    id={control.name}
    value={control.value}
    onChange={handleChangeInput}
    onKeyDown={keydownHandler}
  />;
};
