import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';

import {IArticle} from 'redux/content/interfaces';
import {contentAsyncActions, saveJournal} from 'redux/pageAsyncActions';
import {ButtonVariant} from 'components/gritx-button/ButtonVariantEnum';
import GritxButton from 'components/gritx-button';
import Loader from 'components/loader';

import {PreviewPage} from './PreviewPage';
import {JournalPage} from './JournalPage';

import './styles.scss';

interface IJournalArticle {
  card: IArticle
  onStart: () => void
  onStop: () => void
  onChangePage: (page: number | null) => void
}

export const Journal = ({
  card,
  onStart,
  onStop,
  onChangePage
}: IJournalArticle): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isStartJournal, setIsStartJournal] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [journal, setJournal] = useState<IArticle | null>(null);
  const [isShowPreview, setIsShowPreview] = useState(false);
  const [isShowLastEntry, setIsShowLastEntry] = useState(false);

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  function forgetIt() {
    if (card && card.pages.length === 1) {
      setIsLastPage(true);
    } else {
      setIsLastPage(false);
    }
    setIsStartJournal(false);
    setCurrentPage(0);
    setJournal({
      ...card,
      pages: [...card.pages]
    });
    setIsShowPreview(false);
    onStop();
  }

  useEffect(() => {
    setJournal({
      ...card,
      pages: [...card.pages]
    });
  }, [card]);
  useEffect(() => {
    forgetIt();
  }, [card.id]);
  useEffect(() => {
    onChangePage(currentPage);
  }, [currentPage]);
  useEffect(() => {
    function handleCloseTab(e: BeforeUnloadEvent) {
      if (isStartJournal) {
        const message = intl.formatMessage({
          id: 'common.notification.close-tab',
          defaultMessage: 'Changes you made may not be saved.'
        });

        (e || window.event).returnValue = message;

        return message;
      }

      return null;
    }

    window.addEventListener('beforeunload', handleCloseTab);

    return () => window.removeEventListener('beforeunload', handleCloseTab);
  }, [isStartJournal]);

  function handleStartJournal() {
    setIsStartJournal(true);
    setIsShowLastEntry(false);
    setJournal({
      ...card,
      pages: card.pages.map(page => ({
        ...page,
        text: ''
      }))
    });
    onStart();
    scrollToTop();
  }

  function handleViewLastEntry() {
    setJournal({
      ...card,
      pages: [...card.pages]
    });
    setIsShowLastEntry(true);
  }

  function getLastEntryStatus(): boolean {
    if (journal?.pages?.length) {
      return !journal.pages.find(page => page?.text);
    }

    return true;
  }

  function getIsDisabledRemember(): boolean {
    if (journal?.pages?.length) {
      const answers = journal.pages.filter(page => page?.text);

      if (answers.length > 1) {
        return false;
      }
      if (answers.length === 1 && answers[0].text.length > 1) {
        return false;
      }
    }

    return true;
  }

  function handleChangeAnswer(model: IArticle) {
    setJournal(model);
  }

  function handleChangePage(btn: 'next' | 'prev') {
    if (!journal) {
      return;
    }
    if (btn === 'next') {
      if (currentPage + 1 === journal.pages.length - 1) {
        setIsLastPage(true);
      }
      setCurrentPage(currentPage + 1);
    } else {
      if (currentPage === 0) {
        return;
      }
      setIsLastPage(false);
      setCurrentPage(currentPage - 1);
    }
    scrollToTop();
  }

  function handlePressDone() {
    setIsShowPreview(true);
    onChangePage(null);
    scrollToTop();
  }

  function handleRememberJournal() {
    if (journal) {
      dispatch(contentAsyncActions[saveJournal](journal));
      forgetIt();
    }
  }

  if (!journal) {
    return <Loader/>;
  }

  const renderLastEntry = () => {
    return <>
      <PreviewPage journal={journal}/>
      {isShowPreview && <div className="journal__page-controls">
        <GritxButton
          className="journal__left-button"
          title={intl.formatMessage({
            id: 'content-page.journal.forget-it',
            defaultMessage: 'Forget it'
          })}
          variant={ButtonVariant.Outline}
          onClick={forgetIt}
        />
        <GritxButton
          className="journal__right-button"
          title={intl.formatMessage({
            id: 'content-page.journal.remember',
            defaultMessage: 'Remember'
          })}
          variant={ButtonVariant.Primary}
          onClick={handleRememberJournal}
          disabled={getIsDisabledRemember()}
        />
      </div>}
      {
        isShowLastEntry && <div className="journal__buttons journal__buttons--last-entry">
          <GritxButton
            className="journal__right-button"
            title={intl.formatMessage({
              id: 'content-page.journal.start',
              defaultMessage: 'Add info'
            })}
            variant={ButtonVariant.Primary}
            onClick={handleStartJournal}
          />
        </div>
      }
    </>;
  };

  if (!isStartJournal && !isShowLastEntry) {
    return <div className="journal__buttons">
      {
        !getLastEntryStatus()
        && <GritxButton
          className="journal__left-button"
          title={intl.formatMessage({
            id: 'content-page.journal.view',
            defaultMessage: 'View info'
          })}
          disabled={getLastEntryStatus()}
          variant={ButtonVariant.Outline}
          onClick={handleViewLastEntry}
        />
      }
      <GritxButton
        className="journal__right-button"
        title={intl.formatMessage({
          id: 'content-page.journal.start',
          defaultMessage: 'Add info'
        })}
        variant={ButtonVariant.Primary}
        onClick={handleStartJournal}
        disabled={journal.pages.length === 0}
      />
    </div>;
  }

  if (!isShowPreview && !isShowLastEntry) {
    return <JournalPage
      journal={journal}
      currentPage={currentPage}
      isLastPage={isLastPage}
      onChangePage={handleChangePage}
      onChangeAnswer={handleChangeAnswer}
      onClickDone={handlePressDone}
    />;
  }

  return <div className="journal">
    {renderLastEntry()}
  </div>;
};
