import {useEffect} from 'react';
import {ITranslations} from 'redux/translation/interfaces';
import {IStore} from 'redux/interface';
import {ThunkDispatch} from 'redux-thunk';
import {Action} from 'redux';
import {loadTranslation} from 'redux/translation/translationAsyncActions';

interface IUseGetTranslations {
  translations: ITranslations
  locale: string
  translationKey: string
  dispatch: ThunkDispatch<IStore, unknown, Action>
}

export const useGetTranslations = ({
  translations,
  locale,
  translationKey,
  dispatch
}: IUseGetTranslations): void => {
  useEffect(() => {
    if (!Object.keys(translations[locale])
      .find((key: string): boolean => key.includes(translationKey))) {
      dispatch(loadTranslation(translationKey));
    }
  }, [locale]);
};
