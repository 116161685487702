import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {IStore} from 'redux/interface';
import {SnackBarItem} from './SnackBarItem';

import './styles.scss';

export const SnackBar: React.FunctionComponent = () => {
  const {
    status: {errors}
  } = useSelector((state: IStore) => state);
  const [errorList, setErrorList] = useState<string[]>([]);

  useEffect(() => {
    setErrorList(Object.keys(errors));
  }, [Object.keys(errors).length]);

  function handleRemove(key: string) {
    return () => setErrorList(errorList.filter(eKey => eKey !== key));
  }

  return <div className="snackbar">
    {
      errorList.map(errorKey => {
        return <SnackBarItem
          key={errorKey}
          errorKey={errorKey}
          onClear={handleRemove(errorKey)}
        />;
      })
    }
  </div>;
};
