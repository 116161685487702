import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {FormattedMessage, useIntl} from 'react-intl';

import {IStore} from 'redux/interface';
import {getLibContent, getLibFilters, getTranslations, libraryAsyncActions} from 'redux/pageAsyncActions';
import {IFilter} from 'redux/content/interfaces';
import {maxArticleCards} from 'utils/constants/constants';
import {useGetTranslations} from 'utils/hooks/useGetTranslations';

import CrossroadImg from 'assets/image/library/skill-crossroad.svg';

import CardSection from 'components/card-section';
import NotFoundInfo from 'components/not-found-info';

import './styles.scss';

export const Library: React.FunctionComponent = () => {
  const {
    translation: {
      locale,
      translations
    },
    content: {
      content,
      filters: libFilters
    },
    status: {
      loading
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  // const [selectedFilters, setSelectedFilters] = useState<IFilter[]>([]);

  function loadContent({
    filters,
    offset
  }: { filters: IFilter[]; offset: number }) {
    dispatch(libraryAsyncActions[getLibContent](
      {
        filters,
        offset,
        pageSize: maxArticleCards
      }
    ));
  }

  useGetTranslations({
    locale,
    translations,
    dispatch,
    translationKey: 'library'
  });

  useEffect(() => {
    console.log(libFilters);
    if (libFilters) {
      loadContent({
        filters: libFilters.filters,
        offset: 0
      });
    }
  }, [libFilters]);
  useEffect(() => {
    dispatch(libraryAsyncActions[getLibFilters]());
  }, []);

  function handleLoadMore(offset: number) {
    if (libFilters) {
      loadContent({
        filters: libFilters.filters,
        offset
      });
    }
  }

  // function handleChangeFilters(changedFilters: IFilter[]) {
  //   setSelectedFilters(changedFilters);
  //   loadContent({
  //     filters: changedFilters,
  //     offset: 0
  //   });
  // }

  function handleOpenCard(cardId: number) {
    history.push(
      `/content#${cardId}`,
      history.location.state ? history.location.state : {parentLocation: history.location.pathname}
    );
  }

  // function renderFilters() {
  //   if (libFilters) {
  //     return <LibraryFilters filters={libFilters} onChange={handleChangeFilters}/>;
  //   }
  //
  //   return null;
  // }

  return <section className={'library container'}>
    {
      !loading.includes(getTranslations) && <>
        <h1 className="library__title">
          <FormattedMessage
            id={'library.title'}
            defaultMessage={'Search'}
          />
        </h1>
        <div className="library__border"/>
        <div className="library__cards">
          {content.length === 0 && libFilters && !loading.includes(getLibContent) ? <NotFoundInfo
            title={intl.formatMessage({
              id: 'library.sectionCards.notFound',
              defaultMessage: 'Sorry, but nothing can be found. Try the search or apply fewer filters.'
            })}
            image={CrossroadImg}
          /> : <CardSection
            showLoader={loading.includes(getLibContent)}
            cards={content}
            onLoadMore={handleLoadMore}
            onClickCard={handleOpenCard}
          />}
        </div>
      </>
    }
  </section>;
};
