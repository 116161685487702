import React, {ChangeEvent, KeyboardEvent, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import cn from 'classnames';

import {isEmailValidator, isRequiredValidator} from 'utils/helpers/validators';

import './styles.scss';

interface IInput {
  type?: 'text' | 'email'
  className?: string
  label?: string
  value?: string
  maxLength?: number
  placeholder?: string
  required?: boolean
  onChange?: (value: string) => void
  onBlur?: (value: string) => void
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void
}

const inputErrorTypes = {
  required: 'required',
  email: 'email'
};

const errorMessage: { [type: string]: { id: string; defaultMessage: string } } = {
  [inputErrorTypes.required]: {
    id: 'common.errors.required',
    defaultMessage: 'This field cannot be blank'
  },
  [inputErrorTypes.email]: {
    id: 'common.errors.email',
    defaultMessage: 'The e-mail address is invalid'
  }
};

function validateValue(type: string, value: string, required?: boolean): string[] {
  let errors = [] as string[];

  if (type === 'email') {
    if (!isEmailValidator(value)) {
      errors = [inputErrorTypes.email];
    }
  }
  if (required && !isRequiredValidator(value)) {
    errors = [inputErrorTypes.required];
  }

  if (!required && value.length === 0) {
    errors = [];
  }

  return errors;
}

export const Input: React.FunctionComponent<IInput> = ({
  type = 'text',
  label,
  required = false,
  onChange,
  onBlur,
  onKeyUp,
  className = '',
  placeholder,
  value,
  ...props
}: IInput) => {
  const [errors, setErrors] = useState<string[]>([]);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setErrors([]);
    if (onChange) {
      onChange(e.target.value);
    }
  }

  function handleBlur(e: ChangeEvent<HTMLInputElement>) {
    setErrors(validateValue(type, e.target.value, required));
    if (onBlur) {
      onBlur(e.target.value);
    }
  }

  function handleKeyUp(e: KeyboardEvent<HTMLInputElement>) {
    if (onKeyUp) {
      onKeyUp(e);
    }
  }

  return <div className={`input ${className}`}>
    <label className={'input__label'}>
      <span className={'input__label-text'}>
        {label}
        {required && <span className={'input__required'}>*</span>}
      </span>
      <input
        className={cn(
          'input__field',
          {
            'input__field--error': Boolean(errors.length)
          }
        )}
        type={type}
        value={value}
        placeholder={placeholder}
        required={required}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyPress={handleKeyUp}
        {...props}
      />
    </label>
    {
      errors.map((e, i) => <span key={i} className="input__error">
        {errorMessage[e] && <FormattedMessage {...errorMessage[e]}/>}
      </span>)
    }
  </div>;
};
