import React, {ChangeEvent, FunctionComponent} from 'react';
import {IOverlayInput} from 'components/overlay/interfaces';

import './styles.scss';

interface IDCheckboxList {
  control: IOverlayInput
  onChange: (value: string) => void
  onPressEnter: () => void
}

export const DRadiobutton: FunctionComponent<IDCheckboxList> = ({
  control,
  onChange,
  onPressEnter
}: IDCheckboxList) => {
  function handleChangeRadio(e: ChangeEvent<HTMLInputElement>) {
    onChange(e.target.value);
  }

  const keydownHandler = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onPressEnter();
    }
  };

  return <div className="d-radio">
    {
      control.list?.map((radio) => {
        return <label
          key={radio.id}
          className="d-radio__label"
        >
          <input
            className="d-radio__field"
            type={control.type}
            name={control.name}
            value={radio.value}
            checked={radio.value === control.value}
            onChange={handleChangeRadio}
            onKeyDown={keydownHandler}
          />
          {radio.label}
        </label>;
      })
    }
  </div>;
};
