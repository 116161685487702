import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {IStore} from 'redux/interface';
import {Action} from 'redux';
import {changeLocale} from 'redux/translation/translationAsyncActions';
import {Auth0Client} from '@auth0/auth0-spa-js';
import {IUser, IUserProfile} from './interfaces';
import {
  clearUserAction, loginErrorActionClear,
  loginErrorActionSet,
  setBotAvatarsAction, setProfileAvatarAction,
  setUserAction, setUserAvatarsAction,
  setUserLanguageAction, updateBotAvatarAction, updateUserAvatarAction
} from './actions';
import {setAxiosHeaders} from 'redux/api/helpers';
import {getUserAvatarApi, getUserProfileApi, updateUserProfileApi, updateUserProfileAvatarApi} from 'redux/api/user';
import {getAvatarsApi} from 'redux/api/media';
import {loadMediaAction} from 'redux/media/mediaAsyncActions';
import {errorHandler} from 'redux/errorHandler';
import {proUser, useProUserSystem} from 'utils/constants/constants';
import auth0Instance from '../../utils/auth0/auth0Client';

const loginErrorHandler = async ({
  dispatch,
  auth0Client
}: { dispatch: ThunkDispatch<IStore, unknown, Action>, auth0Client: Auth0Client }) => {
  try {
    await auth0Client.logout();
  } catch (e) {
    console.log('loginErrorHandler', e);
  }
  dispatch(loginErrorActionSet());
};

export const startLoginAction = (): ThunkAction<void, IStore, unknown, Action> => async (dispatch, getState) => {
  const {auth: {auth0Client} } = getState();

  dispatch(loginErrorActionClear());
  try {
    await auth0Client.loginWithRedirect({
      redirect_uri: `${window.location.origin}/callback`
    });
  } catch (e) {
    console.log('startLogin error', e.message);
    await loginErrorHandler({
      dispatch,
      auth0Client
    });
  }
};

export const getUserData = (): ThunkAction<void, IStore, unknown, Action> => async (dispatch) => {
  try {
    const userData = await getUserProfileApi();

    const data = userData.data as IUserProfile;

    if (data.language) {
      dispatch(changeLocale(data.language));
    }

    dispatch(setUserLanguageAction(data));
  } catch (e) {
    console.log('user error', e.message);
    dispatch(errorHandler({
      actionKey: 'getUserData',
      error: e,
      currentAction: getUserData()
    }));
  }
};

export const getProfileAvatar = (): ThunkAction<void, IStore, unknown, Action> => async (dispatch) => {
  try {
    const {data} = await getUserAvatarApi();

    dispatch(setProfileAvatarAction(data));
  } catch (e) {
    console.log('user error', e.message);
    dispatch(errorHandler({
      actionKey: 'getProfileAvatar',
      error: e
    }));
  }
};

export const getBotAvatars = (): ThunkAction<void, IStore, unknown, Action> => async (dispatch, getState) => {
  try {
    const {data: botAvatars} = await getAvatarsApi('BOT');
    const {media} = getState();

    const updatedAvatars = botAvatars.map((avatar) => {
      if (!avatar.fileUrl) {
        return {
          ...avatar,
          fileUrl: media[avatar.fileId] || ''
        };
      }

      return avatar;
    });

    updatedAvatars.forEach((avatar) => {
      if (!avatar.fileUrl) {
        dispatch(loadMediaAction(
          avatar.fileId,
          (url: string) => dispatch(updateBotAvatarAction({
            ...avatar,
            fileUrl: url
          }))
        ));
      }
    });
    dispatch(setBotAvatarsAction(updatedAvatars));
  } catch (e) {
    console.log('user error', e.message);
    dispatch(errorHandler({
      actionKey: 'getBotAvatars',
      error: e
    }));
  }
};

export const getUserAvatars = (): ThunkAction<void, IStore, unknown, Action> => async (dispatch, getState) => {
  try {
    const {data: userAvatars} = await getAvatarsApi('USER');
    const {media} = getState();

    const updatedAvatars = userAvatars.map((avatar) => {
      if (!avatar.fileUrl) {
        return {
          ...avatar,
          fileUrl: media[avatar.fileId] || ''
        };
      }

      return avatar;
    });

    updatedAvatars.forEach((avatar) => {
      if (!avatar.fileUrl) {
        dispatch(loadMediaAction(
          avatar.fileId,
          (url) => dispatch(updateUserAvatarAction({
            ...avatar,
            fileUrl: url
          }))
        ));
      }
    });

    dispatch(setUserAvatarsAction(updatedAvatars));
  } catch (e) {
    console.log('user error', e.message);
    dispatch(errorHandler({
      actionKey: 'getUserAvatars',
      error: e
    }));
  }
};

export const updateUserProfileAction = (profile: IUserProfile): ThunkAction<void, IStore, unknown, Action> => async (dispatch) => {
  try {
    const {data} = await updateUserProfileApi(profile);

    dispatch(setUserLanguageAction(data));
  } catch (e) {
    console.log(e);
    dispatch(errorHandler({
      actionKey: 'loadImageByIdAction',
      error: e
    }));
  }
};
export const updateUserProfileAvatarAction = (fileId: number): ThunkAction<void, IStore, unknown, Action> => async (dispatch) => {
  try {
    await updateUserProfileAvatarApi(fileId);
  } catch (e) {
    console.log(e);
    dispatch(errorHandler({
      actionKey: 'updateUserProfile',
      error: e
    }));
  }
};

export const finishLoginAction = (): ThunkAction<void, IStore, unknown, Action> => async (dispatch, getState) => {
  const {auth: {auth0Client} } = getState();

  try {
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser() as IUser || {};
    const token = await auth0Client.getTokenSilently();

    user.isPro = !useProUserSystem;

    if (useProUserSystem && user['https://gritx.org/roles'] && user['https://gritx.org/roles'].includes(proUser)) {
      user.isPro = true;
    }
    setAxiosHeaders(user, token);

    console.log('USER', user);

    dispatch(loginErrorActionClear());
    dispatch(setUserAction(user));
    dispatch(getUserData());
  } catch (e) {
    console.log('finishLogin error', e.message);
    // await loginErrorHandler({
    //   dispatch,
    //   auth0Client
    // });
  }
};

export const logoutAction = (): ThunkAction<void, IStore, unknown, Action> => async (dispatch, getState) => {
  const {auth: {auth0Client} } = getState();

  dispatch(loginErrorActionClear());

  try {
    await auth0Client.logout({
      returnTo: 'https://well-advised.com'
    });
    dispatch(clearUserAction());
  } catch (e) {
    console.log('logoutAction error', e.message);
    await loginErrorHandler({
      dispatch,
      auth0Client
    });
  }
};

export const getAuthorizationAction = (): ThunkAction<void, IStore, unknown, Action> => async (dispatch, getState) => {
  const {auth: {auth0Client} } = getState();

  dispatch(loginErrorActionClear());

  try {
    const isAuthenticated = await auth0Client.isAuthenticated();

    if (isAuthenticated) {
      const user = await auth0Client.getUser() as IUser || {};
      const token = await auth0Client.getTokenSilently();

      user.isPro = !useProUserSystem;

      if (useProUserSystem && user['https://gritx.org/roles'] && user['https://gritx.org/roles'].includes(proUser)) {
        user.isPro = true;
      }
      setAxiosHeaders(user, token);
      dispatch(setUserAction(user as IUser));
      dispatch(getUserData());
    }
  } catch (e) {
    console.log('getAuthorizationAction error', e.message);
    await loginErrorHandler({
      dispatch,
      auth0Client
    });
  }
};
