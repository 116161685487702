import {BotActionType, IBotAction, IBotAnswer} from 'utils/hooks/use-chat-bot/interfaces';
import {IAutocompleteComponentData, IListComponentData, IOverlayActionControl} from 'components/overlay/interfaces';

export class BotAnswer {
  answer: IBotAnswer;

  constructor(botAnswer: IBotAnswer) {
    this.answer = {
      ...botAnswer
    };
  }

  getContentId(): number | null {
    return this.answer.contentId || null;
  }

  getMessageId(): number {
    return this.answer.messageId;
  }

  getType(): string {
    return this.answer.type;
  }

  getText(): string {
    return this.answer.text && this.answer.text.trim();
  }

  getButtons(): IBotAction[] | null {
    return (this.answer.keyboard && this.answer.keyboard.buttons) || null;
  }

  getUrls(): string[] {
    return this.answer.urls || [];
  }

  getPictureUrl(): string | undefined {
    return (this.answer.picture && this.answer.picture.fileUrl) || undefined;
  }

  getDate(): string {
    return this.answer.date;
  }

  getOverlays(): IBotAction[] | null {
    const buttons = this.getButtons();

    if (buttons) {
      return buttons.filter(button => button.actionType === BotActionType.Overlay);
    }

    return null;
  }

  getOverlayListComponentData(): IListComponentData | null {
    const overlays = this.getOverlays();
    let listComponentData: IListComponentData | null = null;

    if (overlays) {
      const parsedOverlays = overlays.map((overlay, overlayIdx) => ({
        ...overlay,
        overlayIdx,
        parsedActionBody: JSON.parse(overlay.actionBody)
      }));

      parsedOverlays.find((overlay, overlayIdx) => {
        const {
          form: {controls}
        } = overlay.parsedActionBody.addActionBody;

        const controlWithList = controls.find((control: IOverlayActionControl, controlIdx: number) => {
          const input = control.inputs && control.inputs.find((inputItem, inputIdx) => {
            if (inputItem.type === 'list') {
              listComponentData = {
                overlayIdx,
                controlIdx,
                inputIdx,
                inputComponent: inputItem,
                componentOverlay: overlay
              };

              return true;
            }

            return false;
          });

          return !!input;
        });

        return !!controlWithList;
      });
    }

    return listComponentData;
  }

  getOverlayAutocompleteComponentData(): IAutocompleteComponentData | null {
    const overlays = this.getOverlays();
    let autocompleteComponentData: IAutocompleteComponentData | null = null;

    if (overlays) {
      const parsedOverlays = overlays.map((overlay, overlayIdx) => ({
        ...overlay,
        overlayIdx,
        parsedActionBody: JSON.parse(overlay.actionBody)
      }));

      parsedOverlays.find((overlay, overlayIdx) => {
        const {
          form: {controls}
        } = overlay.parsedActionBody.addActionBody;

        const controlWithAutocomplete = controls.find((control: IOverlayActionControl, controlIdx: number) => {
          const input = control.inputs && control.inputs.find((inputItem, inputIdx) => {
            if (inputItem.type === 'autocomplete') {
              autocompleteComponentData = {
                overlayIdx,
                controlIdx,
                inputIdx,
                inputComponent: inputItem,
                componentOverlay: overlay
              };

              return true;
            }

            return false;
          });

          return !!input;
        });

        return !!controlWithAutocomplete;
      });
    }

    return autocompleteComponentData;
  }

  getExternalComponentsData():IBotAction | null {
    const overlays = this.getOverlays();
    let externalComponentData: IBotAction | null = null;

    if (overlays) {
      const parsedOverlays = overlays.map((overlay, overlayIdx) => ({
        ...overlay,
        overlayIdx,
        parsedActionBody: JSON.parse(overlay.actionBody)
      }));

      parsedOverlays.find((overlay) => {
        const {
          form: {controls}
        } = overlay.parsedActionBody.addActionBody;
        const externalControl = controls.find((control: IOverlayActionControl) => {
          if (!control.showInDialog) {
            externalComponentData = overlay;

            return true;
          }

          return false;
        });

        return !!externalControl;
      });
    }

    return externalComponentData;
  }
}
