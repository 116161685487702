import {IOverlayAction, IOverlaySuggestion} from '../../../components/overlay/interfaces';

export interface IDialog {
  avatar: number
  botId: number
  xpeditionId: number
  id: number
  lastMessageDate: string
  lastMessageText: string
  name: string
  unreadCount: number
}

export enum MessageType {
  Text = 'TEXT',
  Picture = 'PICTURE',
  Media = 'MEDIA',
  Action = 'ACTION',
  Url = 'URL'
}

export enum HistoryRequestType {
  All = 'ALL',
  Unread = 'UNREAD',
  Onload = 'ONLOAD',
  Last = 'LAST'
}

interface IBotTextMessage {
  text: string
  urls?: string
}

interface IBotPictureMessage {
  picture: {
    description: string
    fileId: number
  }
}

interface IBotMediaMessage {
  media: {
    description: string
    id: number
    type: 'STICKER' | 'GIF'
  }
  fileId?: number
  fieldUrl?: string
}

interface IBotUrlMessage {
  url: string
  description?: string
}

export enum BotType {
  'Chat' = 'chat',
  'Xpedition' = 'xpedition',
  'Interview' = 'interview',
  'WA' = 'wa'
}

export enum BotActionType {
  ReturnValue = 'RETURN_VALUE',
  Schedule = 'SCHEDULE',
  ContentView = 'CONTENT_VIEW',
  Overlay = 'OVERLAY',
  OpenURL = 'OPEN_URL'
}

interface IBotActionMessage {
  button: {
    actionType: BotActionType
    actionUrl?: string
    actionBody: string
    serialNumber?: number
    text?: string
  }
}

export interface IBotAction {
  actionType: BotActionType
  actionUrl?: string
  actionBody: string
  serialNumber?: number
  text?: string
  parsedActionBody?: IOverlayAction
}

export interface IMessageModel {
  [MessageType.Text]: IBotTextMessage
  [MessageType.Picture]: IBotPictureMessage
  [MessageType.Media]: IBotMediaMessage
  [MessageType.Action]: IBotActionMessage
  [MessageType.Url]: IBotUrlMessage
}

interface ISender {
  id: number
  type: string
  name: string
  avatar: number
  zoneOffset: string
  botName: string
}

interface IPrompt {
  prompt: string
  type: string
  order: number
}

export interface IBot {
  'avatar': number,
  'avatarUrl': string
  'description': string
  'fullDescription': string
  'id': number
  'indMain': boolean,
  'name': string
}

export interface IBotAnswer {
  button?: null
  contentId?: number | null
  contentType?: string
  date: string
  dialogueId: number
  keyboard?: null | { buttons: IBotAction[] }
  media?: null
  messageId: number
  picture?: null | { fileId: number, fileUrl: string }
  prompts: IPrompt[]
  sender: ISender
  text: string
  type: MessageType
  unread: boolean
  url?: string | null
  urls?: string[]
}

type SendMessageType = <T extends MessageType>(type: T, data: IMessageModel[T]) => void

export interface IUseChatBot {
  currentDialog: IDialog | null,
  isLoading: boolean
  botAnswer: IBotAnswer[],
  sendMessage: SendMessageType,
  getBotList: () => Promise<IBot[]>,
  xpedition: {
    getXpeditionList: () => void
    xpeditionList: IXpedition[]
    startXpedition: (xpeditionId: number) => void
  },
  dialogMethods: {
    addDialog: (botId: number) => void
    getDialogList: () => Promise<IDialog[]>
    getMainDialog: () => Promise<IDialog | null>
    getDialogById: (dialogId: number) => Promise<IDialog | null>
    makeDialogRead: (dialogId: number) => void
    removeDialog: (dialogId: number) => void
    setDialogAvatar: (dialogId: number, fileId: number) => void
    setDialogName: (dialogId: number, dialogName: string) => void,
    startInterview: (interviewId: number) => void
    setDialogLanguage: (language: string, dialogId?: number) => void
    getAutocompleteDictionary: (serviceUrl: string, params: { [key: string]: string}) => Promise<IOverlaySuggestion[] | null>
  }
}

export interface IXpedition {
  botId: number
  dialogId: null | number
  id: number
  intro: string
  lang: string
  theme: string
  imageUrl: string
  description: string
  percentage: number
}
