import React, {FunctionComponent} from 'react';

import CubesImg from 'assets/image/temp/advisor/cubes.png';

import './styles.scss';

interface ILiteCard {
  id: number
  title: string
  imageUrl: string
  description: string
  onClick?: (cardId: number) => void
}

export const LiteCard: FunctionComponent<ILiteCard> = ({
  id,
  title,
  imageUrl,
  description,
  onClick
}: ILiteCard) => {
  function handleClick() {
    console.log(id);
  }

  return <button className="lite-card" onClick={handleClick}>
    <div className="lite-card__header">
      <h2 className="lite-card__title">
        {title}
      </h2>
    </div>
    <div className="lite-card__body">
      <div className="lite-card__image-wrapper">
        <img
          className="lite-card__image"
          src={imageUrl}
          alt={title}
        />
      </div>
      <div className="lite-card__description">
        {description}
      </div>
    </div>
  </button>;
};
