import React, {FunctionComponent} from 'react';
import parse, {DOMNode} from 'html-react-parser';
import {Element} from 'domhandler';

import {IOverlayActionControl, IOverlayInput} from 'components/overlay/interfaces';
import DCheckboxList from 'components/d-checkbox-list';
import DInput from 'components/d-input';
import DRadiobutton from 'components/d-radiobutton';
import DChoice from 'components/d-choice';
import DButton from 'components/d-button';
import DTextarea from 'components/d-textarea';
import DDatepicker from 'components/d-datepicker';
import DTextMaskInput from 'components/d-textmask';
import DAutocomplete from 'components/d-autocomplete';
import DList from 'components/d-list';

import './styles.scss';
import {FormattedMessage} from 'react-intl';

interface IDForm {
  control: IOverlayActionControl
  onChangeCheckbox: (inputIdx: number, value: boolean) => void
  onChangeRadiobutton: (inputIdx: number, value: string) => void
  onChangeInput: (inputIdx: number, value: string) => void
  onChangeButton: (inputIdx: number, value: string) => void
  onChangeChoice: (inputIdx: number, value: string) => void
  onPressEnter: () => void
}

export const DForm: FunctionComponent<IDForm> = ({
  control,
  onChangeCheckbox,
  onChangeRadiobutton,
  onChangeInput,
  onChangeButton,
  onChangeChoice,
  onPressEnter
}: IDForm) => {
  function handleChangeDCheckbox(inputIdx: number) {
    return (data: boolean) => {
      onChangeCheckbox(inputIdx, data);
    };
  }

  function handleChangeDInput(inputIdx: number) {
    return (data: string) => {
      onChangeInput(inputIdx, data);
    };
  }

  function handleChangeDRadio(inputIdx: number) {
    return (data: string) => {
      onChangeRadiobutton(inputIdx, data);
    };
  }

  function handleChangeDChoice(inputIdx: number) {
    return (data: string) => {
      onChangeChoice(inputIdx, data);
    };
  }

  function handleChangeDButton(inputIdx: number) {
    return (data: string) => {
      onChangeButton(inputIdx, data);
    };
  }

  function getComponent(input: IOverlayInput, inputIdx: number): JSX.Element | null {
    const formComponents: { [key: string]: JSX.Element } = {
      checkbox: <DCheckboxList
        control={input}
        onChange={handleChangeDCheckbox(inputIdx)}
        onPressEnter={onPressEnter}
      />,
      text: <DInput
        control={input}
        onChange={handleChangeDInput(inputIdx)}
        onPressEnter={onPressEnter}
      />,
      textarea: <DTextarea
        control={input}
        onChange={handleChangeDInput(inputIdx)}
        onPressEnter={onPressEnter}
      />,
      radio: <DRadiobutton
        control={input}
        onChange={handleChangeDRadio(inputIdx)}
        onPressEnter={onPressEnter}
      />,
      choice: <DChoice
        control={input}
        onChange={handleChangeDChoice(inputIdx)}
      />,
      button: <DButton
        control={input}
        onChange={handleChangeDButton(inputIdx)}
      />,
      date: <DDatepicker
        control={input}
        onChange={handleChangeDInput(inputIdx)}
        onPressEnter={onPressEnter}
      />,
      textmask: <DTextMaskInput
        control={input}
        onChange={handleChangeDInput(inputIdx)}
        onPressEnter={onPressEnter}
      />,
      list: <span/>,
      autocomplete: <span/>,
      inline_autocomplete: <DAutocomplete
        control={input}
        onChange={handleChangeDInput(inputIdx)}
        onPressEnter={onPressEnter}
      />,
      inline_list: <DList
        control={input}
        onChange={handleChangeDInput(inputIdx)}
        onPressEnter={onPressEnter}
      />
    };

    return formComponents[input.type] || null;
  }

  function parseForm() {
    return parse(control.text, {
      replace: (domNode: DOMNode) => {
        const node = domNode as Element;

        if (node && node.attribs?.id) {
          const id = node.attribs.id;
          const inputIdx = control.inputs.findIndex(item => item.name === id);
          const input = control.inputs[inputIdx];

          return getComponent(input, inputIdx) || <p>
            <FormattedMessage
              id={'common.form.error'}
              defaultMessage={'Unknown input type'}
            />
            <br/>
            {JSON.stringify(control)}
          </p>;
        }
        if (node && node.attribs?.href) {
          node.attribs = {
            ...node.attribs,
            target: '_blank',
            rel: 'noreferrer'
          };
        }

        return node;
      }
    });
  }

  return <>{parseForm()}</>;
};
