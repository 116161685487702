import React, {FunctionComponent, useEffect} from 'react';
import {IOverlayInput, IOverlayRadio} from 'components/overlay/interfaces';

import './styles.scss';
import {Autocomplete, TextField} from '@mui/material';

interface IDList {
  control: IOverlayInput
  onChange: (value: string) => void
  onPressEnter: () => void
}

export const DList: FunctionComponent<IDList> = ({
  control,
  onChange,
  onPressEnter
}: IDList) => {
  const [options, setOptions] = React.useState<readonly IOverlayRadio[]>([]);
  const [value, setValue] = React.useState<IOverlayRadio | null>(null);

  useEffect(() => {
    if (control.list) {
      setOptions(control.list);
      if (control.value) {
        const option = control.list?.find(i => i.value === control.value);

        if (option) {
          setValue(option);
        }
      }
    }
  }, []);

  const keydownHandler = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onPressEnter();
    }
  };

  return <Autocomplete
    id={control.name}
    inputValue={control.value}
    value={value}
    options={options}
    getOptionLabel={(option) => option.label}
    forcePopupIcon={false}
    size="small"
    noOptionsText={''}
    onChange={(event, newValue) => {
      onChange(newValue?.value || '');
      setValue(newValue);
    }}
    renderInput={(params) => (
      <TextField {...params} placeholder={control.placeholder} onKeyDown={keydownHandler} />
    )}
    ListboxProps={{style: {maxHeight: '12rem'} }}
  />;
};
