import React, {useEffect, useState} from 'react';

import {getBotAvatars, getUserAvatars, updateUserProfileAvatarAction} from 'redux/auth/authAsyncActions';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from 'redux/interface';
import {IAvatar} from 'redux/auth/interfaces';

import {Avatar} from 'components/avatar/Avatar';

import UserIcon from 'assets/image/user-settings/user-ico.svg';

import './styles.scss';

export const AvatarList = ({type}: { type: null | 'user' | 'bot' }): JSX.Element => {
  const {
    auth: {
      userAvatars,
      botAvatars
    }
  } = useSelector((state: IStore) => state);

  const dispatch = useDispatch();
  const [avatarsList, setAvatarsList] = useState<IAvatar[]>([]);

  useEffect(() => {
    dispatch(getUserAvatars());
    dispatch(getBotAvatars());
  }, []);

  useEffect(() => {
    let avatars: IAvatar[] = [];

    if (userAvatars) {
      avatars = [...userAvatars];
    }
    if (botAvatars) {
      avatars = [...avatars, ...botAvatars];
    }
    setAvatarsList(avatars);
  }, [userAvatars, botAvatars]);

  function handleChangeAvatar(fileId: number) {
    return () => {
      if (type === 'user') {
        dispatch(updateUserProfileAvatarAction(fileId));

        return;
      }
      if (type === 'bot') {
        console.log('dispatch bot set avatar action');

        return;
      }
      console.log('default click action');
    };
  }

  return (
    <div className="avatars__content">
      {
        avatarsList.map((item, index) => {
          return (
            <Avatar
              key={index}
              imgUrl={item.fileUrl || UserIcon}
              imgText={item.text}
              handleClick={handleChangeAvatar(item.fileId)}
            />
          );
        })
      }
    </div>
  );
};
