import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {IArticle} from 'redux/content/interfaces';
import {ButtonVariant} from 'components/gritx-button/ButtonVariantEnum';
import GritxButton from 'components/gritx-button';
import Loader from 'components/loader';
import ModalWindow from 'components/modal-window';

import {useChatBot} from 'utils/hooks/use-chat-bot/useChatBot';
import {InterviewOverlay} from './InterviewOverlay';
import {BotType, IBotAction, MessageType} from 'utils/hooks/use-chat-bot/interfaces';

import './styles.scss';

interface IInterviewArticle {
  card: IArticle
  onStart: () => void
  onFinish: () => void
}

const timeout = 4000; // ms

export const SelfInterview = ({
  card,
  onStart,
  onFinish
}: IInterviewArticle): JSX.Element => {
  const intl = useIntl();
  const {
    botAnswer,
    sendMessage,
    isLoading,
    currentDialog,
    dialogMethods: {
      startInterview,
      removeDialog
    }
  } = useChatBot(BotType.Interview);
  const [isStartInterview, setIsStartInterview] = useState(false);
  const [isFinishInterview, setIsFinishInterview] = useState(false);
  const [isDisabledNextButton, setIsDisabledNextButton] = useState(true);
  const [interviewAnswer, setInterviewAnswer] = useState<IBotAction | null>(null);

  useEffect(() => {
    if (botAnswer.length) {
      setTimeout(() => {
        setIsDisabledNextButton(false);
      }, timeout);
    }
  }, [botAnswer]);

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  function handleStartInterview() {
    setIsStartInterview(true);
    if (card.interviewId) {
      startInterview(card.interviewId);
    }
    onStart();
    scrollToTop();
  }

  function handleChangeInterviewAnswer(answer: IBotAction | null) {
    setInterviewAnswer(answer);
  }

  function handleClickNext() {
    if (!interviewAnswer) {
      return;
    }
    const actionButton = interviewAnswer;

    setIsDisabledNextButton(true);
    setInterviewAnswer(null);
    sendMessage(MessageType.Action, {
      button: {
        actionType: actionButton.actionType,
        actionBody: actionButton.actionBody,
        actionUrl: actionButton.actionUrl,
        serialNumber: actionButton.serialNumber,
        text: actionButton.text
      }
    });
  }

  function handleFinishInterview() {
    setIsFinishInterview(true);
    onFinish();
  }

  function handleCloseInterview() {
    setIsStartInterview(false);
    onFinish();
    if (currentDialog) {
      removeDialog(currentDialog.id);
    }
  }

  if (isStartInterview) {
    return <ModalWindow
      show={isStartInterview}
      className="meditation__modal"
      onHide={handleCloseInterview}
    >
      <div className="interview__page">
        {isLoading && <Loader nested/>}
        <InterviewOverlay
          botAnswers={botAnswer}
          onChange={handleChangeInterviewAnswer}
          onFinish={handleFinishInterview}
        />
        <div className="interview__buttons">
          {
            isFinishInterview
              ? <>
                <GritxButton
                  className="interview__right-button"
                  title={intl.formatMessage({
                    id: 'content-page.self-interview.finish',
                    defaultMessage: 'Finish'
                  })}
                  variant={ButtonVariant.Primary}
                  onClick={handleCloseInterview}
                />
              </>
              : <>
                {
                  !isLoading && <GritxButton
                    className="interview__right-button interview__animated-button"
                    style={{
                      animation: `buttonFade ${timeout}ms ease`
                    }}
                    title={intl.formatMessage({
                      id: 'content-page.self-interview.next',
                      defaultMessage: 'Next'
                    })}
                    variant={ButtonVariant.Primary}
                    disabled={isDisabledNextButton}
                    onClick={handleClickNext}
                  />
                }
              </>
          }
        </div>
      </div>
    </ModalWindow>;
  }

  return <div className="interview__buttons">
    <GritxButton
      className="interview__right-button"
      title={intl.formatMessage({
        id: 'content-page.self-interview.start-over',
        defaultMessage: 'Start Over'
      })}
      variant={ButtonVariant.Primary}
      onClick={handleStartInterview}
    />
  </div>;
};
