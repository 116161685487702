export const tabletsWidth = 640;
export const desktopWidth = 980;
export const largeDesktopWidth = 1280;

export const vimeoId = null;
export const appStoreUrl = '#';
export const intIosAccess = '#';
export const googlePlayUrl = '#';
export const twitterUrl = '#';
export const facebookUrl = '#';
export const instagramUrl = '#';
export const maxArticleCards = 12;
export const useProUserSystem = false;
export const proUser = 'Pro';
export const maxEmails = 40;

export const feedbackTypes = {
  issue: 'issue',
  newContent: 'newContent',
  joinUCSF: 'joinUCSF',
  shareHistory: 'shareHistory',
  chat: 'chat',
  newsletter: 'newsletter'
};

export const emailRegExp = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);
export const fileSize = 10485760;

export enum ContentType {
  Meditation = 'MEDITATION',
  Skill = 'SKILL',
  SelfCare = 'Self-care',
  Journal = 'JOURNAL',
  SelfInterview = 'SELF_INTERVIEW'
}

export const languages = [
  {
    value: 'en',
    title: 'English',
    key: 'common.language.en'
  },
  {
    value: 'es',
    title: 'Spanish',
    key: 'common.language.es'
  }
];

export const RouteVar: { [key: string]: string } = {
  Library: '/search',
  Materials: '/records',
  Backpack: '/todo',
  Chat: '/chat',
  Xpedition: '/advisor',
  Settings: '/settings',
  Content: '/content'
};

export const routeTranslationKeys: { [key: string]: string } = {
  [RouteVar.Library]: 'library',
  [RouteVar.Materials]: 'materials',
  [RouteVar.Backpack]: 'backpack',
  [RouteVar.Chat]: 'chat',
  [RouteVar.Xpedition]: 'chat',
  [RouteVar.Settings]: 'user-settings',
  [RouteVar.Content]: 'content-page'
};
