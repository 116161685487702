import React from 'react';
import {FormattedMessage} from 'react-intl';

import {SettingsForm} from './SettingsForm';

import {IStore} from 'redux/interface';
import {useDispatch, useSelector} from 'react-redux';
import {SET_USER_DATA} from 'redux/auth/actions';
import {getTranslations} from 'redux/pageAsyncActions';

import Loader from 'components/loader';
import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import SettingImg from 'assets/image/user-settings/settings-img.png';

import './styles.scss';

export const UserSettings = (): JSX.Element => {
  const {
    translation: {
      locale,
      translations
    },
    auth: {
      userProfile
    },
    status: {
      loading
    }
  } = useSelector((state: IStore) => state);

  const dispatch = useDispatch();

  useGetTranslations({
    locale,
    translations,
    dispatch,
    translationKey: 'user-settings'
  });

  return (
    <section className="settings">

      {
        !loading.includes(getTranslations) && <>
          <div className="settings__label">
            <img className="settings__image" src={SettingImg} alt="Settings"/>
            <p className="settings__title">
              <FormattedMessage
                id="user-settings.account"
                defaultMessage="Account"
              />
            </p>
          </div>
          <div className="settings__line"></div>
          {
            loading.includes(SET_USER_DATA) || !userProfile
              ? <Loader/>
              : <SettingsForm/>
          }
        </>
      }
    </section>
  );
};
