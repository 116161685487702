import React, {ChangeEvent, FunctionComponent} from 'react';
import {IOverlayInput} from 'components/overlay/interfaces';
import InputMask from 'react-input-mask';

import './styles.scss';
import {TextField} from '@mui/material';

interface IDTextMaskInput {
  control: IOverlayInput
  onChange: (value: string) => void
  onPressEnter: () => void
}

export const DTextMaskInput: FunctionComponent<IDTextMaskInput> = ({
  control,
  onChange,
  onPressEnter
}: IDTextMaskInput) => {
  const [value, setValue] = React.useState<string>(control.value);

  function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value;

    setValue(newValue);

    if (newValue.indexOf('_') >= 0) {
      onChange('');
    } else {
      onChange(newValue);
    }
  }

  const keydownHandler = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onPressEnter();
    }
  };

  return <InputMask
    mask={control.mask}
    alwaysShowMask={true}
    onChange={handleChangeInput}
    value={value}>
    <TextField size="small" onKeyDown={keydownHandler}/>
  </InputMask>;
};
