import React, {FunctionComponent} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {IXpedition} from 'utils/hooks/use-chat-bot/interfaces';
import Loader from 'components/loader';
import {ButtonVariant} from 'components/gritx-button/ButtonVariantEnum';
import GritxButton from 'components/gritx-button';

import {XpeditionItem} from './xpedition-item/XpeditionItem';

interface IXpeditionList {
  xpeditionList: IXpedition[]
  onStart: (xpeditionData: { botId: number, xpedition: IXpedition }) => void
  onRemove: (dialogId: number) => void// todo remove
}

export const XpeditionList: FunctionComponent<IXpeditionList> = ({
  xpeditionList,
  onStart,
  onRemove
}: IXpeditionList) => {
  const intl = useIntl();

  function handleStartXpedition(xpedition: IXpedition) {
    return (botId: number) => {
      onStart({
        botId,
        xpedition
      });
    };
  }

  // todo remove
  function handleRemoveDialog(dialogId: number) {
    onRemove(dialogId);
  }

  // ----------
  function handleHelp() {
    return false;
  }

  if (xpeditionList.length === 0) {
    return <Loader nested/>;
  }

  const renderContent = () => {
    return <>
      {
        xpeditionList.map(item => {
          return <XpeditionItem
            key={item.id}
            xpedition={item}
            onStart={handleStartXpedition(item)}
            onRemove={handleRemoveDialog}// todo remove
          />;
        })
      }
    </>;
  };
  const renderMessages = () => {
    return <div className="bot-chat__wrapper">
      <div className="bot-chat__start-message">
        <p>
          <FormattedMessage
            id={'xpedition.message'}
            defaultMessage={'We\'re here to help you take care of all your healthcare needs.'
              + ' You can tell me what you\'re looking for or select a topic and I will guide you through the process. How can I help you today?'}
          />
        </p>
      </div>
    </div>;
  };

  return <div className="bot">
    <div className="bot__wrapper">
      <div className="bot__window">
        <div className="bot-chat">
          <div className="bot-chat__toolbar">
            <GritxButton
              title={intl.formatMessage({
                id: 'chat.helpButton',
                defaultMessage: 'Help'
              })}
              onClick={handleHelp}
              variant={ButtonVariant.Outline}
              className="bot-chat__help-btn"
              disabled={true}
            />
          </div>
          {renderMessages()}
          <div className="bot-chat__toolbar bot-chat__toolbar--xpedition">
            <GritxButton
              title={intl.formatMessage({
                id: 'common.button.send',
                defaultMessage: 'Send'
              })}
              variant={ButtonVariant.Primary}
              disabled={true}
              className="bot-chat__send-btn"
              onClick={handleHelp}
            />
          </div>
        </div>
      </div>

      <div className="bot__content">
        {renderContent()}
      </div>
    </div>
  </div>;
};
