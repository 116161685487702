import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {IOverlayInput, IOverlaySuggestion} from 'components/overlay/interfaces';

import './styles.scss';
import {Autocomplete, TextField} from '@mui/material';
import Debounce from 'lodash/debounce';
import {useDictionary} from '../../utils/hooks/useDictionary';

interface IDAutocomplete {
  control: IOverlayInput
  onChange: (value: string) => void
  onPressEnter: () => void
}

export const DAutocomplete: FunctionComponent<IDAutocomplete> = ({
  control,
  onChange,
  onPressEnter
}: IDAutocomplete) => {
  const [suggestions, setSuggestions] = useState<readonly IOverlaySuggestion[]>([]);
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState<IOverlaySuggestion | null>(null);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [minQueryLength, setMinQueryLength] = useState(control.request?.minQueryLength === 0 ? 0 : control.request?.minQueryLength || 3);

  const dictionary = useDictionary();

  useEffect(() => {
    if (control.value) {
      const option = {
        name: control.value,
        id: 0
      };

      if (option) {
        setValue(option);
      }
    }
  }, []);

  const fillAutocompleteSuggestions = useCallback(Debounce((search: string, callback: (suggestions: IOverlaySuggestion[]) => void) => {
    if (!(control.request && search.length >= minQueryLength)) {
      return;
    }
    const params = control.request.requestParams?.reduce((data, item) => {
      return {
        ...data,
        [item.code]: (item.input ? search.toUpperCase() : undefined) || item.default
      };
    }, {});

    setIsLoading(true);
    dictionary.getAutocompleteDictionary(control.request.serviceUrl, params)
      .then(data => {
        if (data) {
          callback(data);
        }
        setIsLoading(false);
      });
  }, 300), []);

  useEffect(() => {
    let active = true;

    if (inputValue.length < minQueryLength) {
      setSuggestions([]);
      setOpen(false);

      return undefined;
    }

    fillAutocompleteSuggestions(inputValue, (results?: readonly IOverlaySuggestion[] | null) => {
      if (active) {
        let newOptions: readonly IOverlaySuggestion[] = [];

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setSuggestions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, open, fillAutocompleteSuggestions]);

  const keydownHandler = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onPressEnter();
    }
  };

  return <Autocomplete
    id={control.name}
    value={value}
    inputValue={inputValue}
    options={suggestions}
    getOptionLabel={(option) => option.name}
    filterOptions={(x) => x}
    forcePopupIcon={false}
    size="small"
    loading={isLoading}
    loadingText="..."
    noOptionsText={''}
    open={inputValue.length >= minQueryLength && open}
    onOpen={() => {
      setOpen(true);
    }}
    onClose={() => {
      setOpen(false);
    }}
    onChange={(event, newValue) => {
      onChange(newValue?.name || '');
      setValue(newValue);
    }}
    onInputChange={(event, newInputValue) => {
      setInputValue(newInputValue);
    }}
    renderInput={(params) => (
      <TextField {...params} placeholder={control.placeholder} onKeyDown={keydownHandler} />
    )}
    ListboxProps={{style: {maxHeight: '12rem'} }}
  />;
};
