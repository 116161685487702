import React, {useEffect} from 'react';
import Modal from 'react-modal';
import './styles.scss';

import setIsBodyScrolling from 'utils/helpers/setIsBodyScrolling';
import cn from 'classnames';

type ModalProps = {
  isOpen: boolean;
  onHide?: () => void;
  onCloseWithMeasurements: (measurements: VitalSigns) => void;
};

export declare type VitalSign<T> = {
  value?: T;
};

export declare type HeartRateSign = VitalSign<number>;
export declare type OxygenSaturationSign = VitalSign<number>;
export declare type BreathingRateSign = VitalSign<number>;
export declare type SDNNSign = VitalSign<number>;
export declare type StressLevelSign = VitalSign<number>;
export declare type StressIndexSign = VitalSign<number>;
export declare type BloodPressureSign = VitalSign<BloodPressureValue>;
export declare type RRISign = VitalSign<RRIValue[]>;
export declare type MeanRRISign = VitalSign<number>;
export declare type RmssdSign = VitalSign<number>;
export declare type Sd1Sign = VitalSign<number>;
export declare type Sd2Sign = VitalSign<number>;
export declare type PRQSign = VitalSign<number>;
export declare type PnsIndexSign = VitalSign<number>;
export declare type PnsZoneSign = VitalSign<number>;
export declare type SnsIndexSign = VitalSign<number>;
export declare type SnsZoneSign = VitalSign<number>;
export declare type WellnessLevelSign = VitalSign<number>;
export declare type WellnessIndexSign = VitalSign<number>;
export declare type VitalSigns = {
  heartRate?: HeartRateSign;
  oxygenSaturation?: OxygenSaturationSign;
  breathingRate?: BreathingRateSign;
  sdnn?: SDNNSign;
  stressLevel?: StressLevelSign;
  stressIndex?: StressIndexSign;
  bloodPressure?: BloodPressureSign;
  rri?: RRISign;
  meanRri?: MeanRRISign;
  rmssd?: RmssdSign;
  sd1?: Sd1Sign;
  sd2?: Sd2Sign;
  prq?: PRQSign;
  pnsIndex?: PnsIndexSign;
  pnsZone?: PnsZoneSign;
  snsIndex?: SnsIndexSign;
  snsZone?: SnsZoneSign;
  wellnessLevel?: WellnessLevelSign;
  wellnessIndex?: WellnessIndexSign;
};
export declare type RRIValue = {
  interval: number;
  time: number;
};
export declare type BloodPressureValue = {
  systolic: number;
  diastolic: number;
};

export const BinahModal = ({isOpen, onHide, onCloseWithMeasurements}: ModalProps): JSX.Element => {
  useEffect(() => {
    setIsBodyScrolling(!isOpen);

    return () => setIsBodyScrolling(true);
  }, [isOpen]);

  useEffect(() => {
    const handler = (event: MessageEvent<any>) => {
      console.log('measurements stopped');
      onCloseWithMeasurements(event.data);
    };

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  });

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      className={cn('modal__window', 'modal-binah')}
      overlayClassName="modal__overlay">
      <header className="modal__header">
        <button className={'modal__close-btn'} onClick={onHide}>x</button>
      </header>
      <div className="modal__body">
        <iframe className="modal__iframe" title="binah" src={process.env.REACT_APP_BINAH_URL} allow='camera; cross-origin-isolated'></iframe>
      </div>
      <footer className="modal__footer">
      </footer>
    </Modal>
  );
};
