import React, {useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from 'redux/interface';
import {startLoginAction} from 'redux/auth/authAsyncActions';

export const Login = (): JSX.Element => {
  const {auth: {auth0User} } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => dispatch(startLoginAction()), 1000);
  }, []);

  if (auth0User) {
    return <Redirect to="/advisor"/>;
  }

  return <div>Redirect to login</div>;
};
