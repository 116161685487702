import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';

import {IStore} from 'redux/interface';
import {getTranslations} from 'redux/pageAsyncActions';
import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {BotType, IXpedition} from 'utils/hooks/use-chat-bot/interfaces';

import BotChat from 'components/bot-chat';

import {closeArticle} from 'redux/content/actions';

import './styles.scss';
import {RouteVar} from '../../utils/constants/constants';

export const Xpedition: React.FunctionComponent = () => {
  const [breadcrumbs, setBreadcrumbs] = useState('');
  const {
    translation: {
      locale,
      translations
    },
    status: {loading}
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(closeArticle());
  }, []);

  useGetTranslations({
    locale,
    translations,
    dispatch,
    translationKey: 'chat'
  });

  function handleChangeBreadcrumbs(xpedition: IXpedition) {
    setBreadcrumbs(xpedition.theme);
  }

  return <div className="xpedition">
    {
      !loading.includes(getTranslations) && <>
        <h1 className="xpedition__title">
          {
            breadcrumbs
              ? <span className="xpedition__breadcrumbs">
                <a
                  href={RouteVar.Xpedition}
                  className="xpedition__breadcrumbs-link"
                >
                  <b>
                    <FormattedMessage
                      id={'xpedition.breadcrumbs.cards'}
                      defaultMessage={'Cards'}
                    />
                  </b>
                </a>: <span className="xpedition__breadcrumbs-current">
                  {breadcrumbs}
                </span>
              </span>
              : <>
                <FormattedMessage
                  id={'xpedition.title'}
                  defaultMessage={'Your healthcare advisor is here.'}
                />
              </>
          }
        </h1>
        <BotChat
          mode={BotType.Xpedition}
          onChangeBreadcrumbs={handleChangeBreadcrumbs}
        />
      </>
    }
  </div>;
};
