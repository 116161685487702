import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import cn from 'classnames';

import {Navigation, LanguageMenu} from './Navigation/Navigation';

import {logoutAction, startLoginAction} from 'redux/auth/authAsyncActions';
import {IStore} from 'redux/interface';

import Logo from 'assets/image/header/logo-new.png';
import LoginImg from 'assets/image/home-page/login.svg';
import Menu from 'assets/image/header/menu.svg';
import CloseMenu from 'assets/image/header/close.svg';
import AppStore from 'assets/image/social-mobile/AppStore.svg';
import GoogleApp from 'assets/image/connect-line/downloadViaGooglePlay.svg';

import {appStoreUrl, desktopWidth, googlePlayUrl, intIosAccess} from 'utils/constants/constants';
import {useResize} from 'utils/hooks/useResize';
import setIsBodyScrolling from 'utils/helpers/setIsBodyScrolling';

import './styles.scss';

export const Header: React.FunctionComponent = () => {
  const {
    auth: {
      auth0User,
      userProfile
    }
  } = useSelector((state: IStore) => state);

  const dispatch = useDispatch();

  const [isOpenAuthMenu, setIsOpenAuthMenu] = useState(false);
  const [isOpenGuestMenu, setIsOpenGuestMenu] = useState(false);

  const proFlag = auth0User?.isPro || false;

  const screenWidth = useResize();

  const node = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (screenWidth >= desktopWidth && (isOpenAuthMenu || isOpenGuestMenu)) {
      setIsBodyScrolling(true);
      setIsOpenAuthMenu(false);
      setIsOpenGuestMenu(false);
    }
  }, [screenWidth]);

  const escapeListener = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsOpenAuthMenu(false);
      setIsOpenGuestMenu(false);
    }
  }, []);

  const handleClick = useCallback((event: MouseEvent): void => {
    const element = event.target as HTMLElement;

    if (node.current && node.current.contains(element)) {
      return;
    }
    setIsOpenAuthMenu(false);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('keyup', escapeListener);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('keyup', escapeListener);
    };
  }, []);

  async function handleSignIn() {
    if (isOpenGuestMenu) {
      setIsOpenGuestMenu(false);
    }
    dispatch(startLoginAction());
  }

  function handleSignOut() {
    dispatch(logoutAction());
    if (isOpenAuthMenu) {
      setIsOpenAuthMenu(false);
    }
  }

  function handleBurgerButton() {
    setIsBodyScrolling(isOpenGuestMenu);
    setIsOpenGuestMenu(!isOpenGuestMenu);
  }

  function handleMenuAuthButton() {
    setIsOpenAuthMenu(!isOpenAuthMenu);
  }

  function handleScrollTop() {
    window.scrollTo(0, 0);
  }

  function handleCloseAuthMenu() {
    setIsOpenAuthMenu(false);
  }

  // function handleClickSettings() {
  //   if (!translationKeys.includes(routeTranslationKeys['/settings'])) {
  //     dispatch(loadTranslation(routeTranslationKeys['/settings']));
  //   }
  //   setIsOpenAuthMenu(false);
  // }

  function showUserInitials() {
    return (
      (userProfile && userProfile.firstName && userProfile.lastName)
        ? `${userProfile.firstName.substr(0, 1)}${userProfile.lastName.substr(0, 1)}`
        : auth0User?.nickname.substr(0, 2)
    );
  }

  const ButtonSignIn = () => {
    return (
      <div className="header-button-wrapper">
        {auth0User === null ? (
          <button
            className="header-button default-signIn-btn"
            onClick={handleSignIn}
          >
            <img className={'signIn-btn-image'} src={LoginImg} alt="Login"/>
            <FormattedMessage
              id={'common.header.signIn'}
              defaultMessage={'Sign In'}
            />
          </button>
        ) : (
          <button
            className="header-button default-signIn-btn"
            onClick={handleSignOut}
          >
            <img className={'signIn-btn-image'} src={LoginImg} alt="Login"/>
            <FormattedMessage
              id={'common.header.signOut'}
              defaultMessage={'Sign Out'}
            />
          </button>
        )}
      </div>
    );
  };

  const ButtonAuthUser = () => {
    return (
      <div className="header-button-wrapper">
        {auth0User !== null ? (
          <button
            className={'default-auth-user'}
            onClick={handleMenuAuthButton}
          >
            <img className={'signIn-btn-image'} src={LoginImg} alt="Login"/>
            <p className={'default-auth-user__name'}>
              {showUserInitials()}
            </p>
          </button>
        ) : null}
      </div>
    );
  };

  const MenuAuthUser = () => {
    return (
      <ul className={'menu-auth__items'}>
        {/* todo uncomment when added user settings*/}
        {/* <li className={'menu-auth__item'}>*/}
        {/* <NavLink*/}
        {/*  className={'menu-auth__btn'}*/}
        {/*  to={'/settings'}*/}
        {/*  onClick={handleClickSettings}*/}
        {/* >*/}
        {/*  <span className={'menu-auth__name'}>*/}
        {/*    {showUserInitials()}*/}
        {/*  </span>*/}
        {/*  <p className={'menu-auth__text'}>*/}
        {/*    <FormattedMessage*/}
        {/*      id={'common.header.settings-description'}*/}
        {/*      defaultMessage={'Name, contacts, avatar, language, password'}*/}
        {/*    />*/}
        {/*  </p>*/}
        {/* </NavLink>*/}
        {/* </li>*/}
        <li className={'menu-auth__item'}>
          <button className={'menu-auth__btn'} onClick={handleSignOut}>
            <FormattedMessage
              id={'common.header.logout'}
              defaultMessage={'Logout'}
            />
          </button>
        </li>
      </ul>
    );
  };

  return (
    <header className="header">
      <div className="container">
        <div className="header__wrapper">
          <div className="header__left">
            <button className="header__logo" onClick={handleScrollTop}>
              <img className="header__logo-image" src={Logo} alt="Logo"/>
            </button>
            {auth0User === null
              ? <LanguageMenu/>
              : (
                <Navigation
                  className={'nav__desktop'}
                  proUser={proFlag}
                  onOpenMenu={handleCloseAuthMenu}
                />
              )}
          </div>
          <div className="header__right">
            {auth0User === null ? <ButtonSignIn/> : <ButtonAuthUser/>}

            {auth0User === null ? (
              <button className="burger__item" onClick={handleBurgerButton}>
                {isOpenGuestMenu ? (
                  <img
                    className={'burger__image'}
                    src={CloseMenu}
                    alt="Close Menu"
                  />
                ) : (
                  <img className={'burger__image'} src={Menu} alt="Menu"/>
                )}
              </button>
            ) : null}
          </div>
        </div>

        {auth0User === null ? (
          <div
            className={cn('header__menu', {
              'header__menu--hidden': !isOpenGuestMenu
            })}
          >
            <div className="menu">
              <div className="menu__content">
                <div className="menu__store">
                  <a
                    target={'_blank'}
                    rel={'noreferrer'}
                    href={appStoreUrl}
                    className={'menu__store-link'}
                  >
                    <img
                      className="menu__store-img"
                      src={AppStore}
                      alt="App Store"
                    />
                  </a>
                </div>
                <p className="menu__content-button">
                  <a
                    target={'_blank'}
                    rel={'noreferrer'}
                    href={intIosAccess}
                    className={'menu__content-link'}
                  >
                    International iOS Access
                  </a>
                </p>
                <div className="menu__store">
                  <a
                    target={'_blank'}
                    rel={'noreferrer'}
                    href={googlePlayUrl}
                    className={'menu__store-link'}
                  >
                    <img
                      className="menu__store-img"
                      src={GoogleApp}
                      alt="Google Play"
                    />
                  </a>
                </div>
                <button className="menu__button" onClick={handleSignIn}>
                  <img
                    className="menu__button-image"
                    src={LoginImg}
                    alt="Login"
                  />
                  <FormattedMessage
                    id={'common.header.signIn'}
                    defaultMessage={'Sign In'}
                  />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div ref={node} className={'menu-auth--opened'}>
            <div
              className={cn('menu-auth', {'menu-auth--hidden': !isOpenAuthMenu})}
            >
              <Navigation
                className={'nav__mobile'}
                proUser={proFlag}
                onOpenMenu={handleCloseAuthMenu}
              />
              <MenuAuthUser/>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};
