import React, {ChangeEvent, FunctionComponent} from 'react';
import {IOverlayInput} from 'components/overlay/interfaces';
import './styles.scss';

interface IDCheckboxList {
  control: IOverlayInput
  onChange: (value: boolean) => void
  onPressEnter: () => void
}

export const DCheckboxList: FunctionComponent<IDCheckboxList> = ({
  control,
  onChange,
  onPressEnter
}: IDCheckboxList) => {
  function handleChangeCheckbox(e: ChangeEvent<HTMLInputElement>) {
    onChange(e.target.checked);
  }

  const keydownHandler = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onPressEnter();
    }
  };

  return <label key={control.name} className="d-checkbox d-checkbox__label">
    <input
      className="d-checkbox__field"
      type={control.type}
      checked={control.value !== 'No'}
      name={control.name}
      onChange={handleChangeCheckbox}
      onKeyDown={keydownHandler}
    />
    {control.label}
  </label>;
};
