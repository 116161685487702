import React, {FunctionComponent} from 'react';
import {FormattedMessage} from 'react-intl';

import './styles.scss';

export const Term: FunctionComponent = () => {
  return <div className="term">
    <header className="term__title-window">
      <FormattedMessage
        id={'terms.titleTerms'}
        defaultMessage={'Terms and Conditions'}
      />
    </header>
    <p className="term__description">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum cupiditate delectus deleniti distinctio dolore enim est et facilis fuga fugiat illo
      inventore iure magni maiores nobis odit officia officiis, praesentium quae repudiandae ullam veniam vero. Doloremque ducimus ipsam iste maiores non odio
      recusandae, ullam unde ut velit! Aliquid aspernatur, assumenda commodi consequatur dicta distinctio dolor dolore doloribus eius eos eum, eveniet ex fuga
      fugiat labore molestiae necessitatibus neque non odio perspiciatis quaerat qui quod quos rem sit! At atque debitis deleniti distinctio dolores error fuga
      illum, laborum modi odit officia provident rerum tempore, ut veritatis voluptas voluptate. Commodi minima recusandae unde veniam? Alias aliquid, aperiam
      architecto at, consectetur consequuntur corporis dolores doloribus eligendi eos exercitationem explicabo harum hic illo in ipsum iure labore magnam
      molestiae molestias nam nemo obcaecati optio possimus quis quos recusandae sint sit sunt tenetur totam vel voluptas voluptatum! Amet aut itaque minus
      officia officiis quia veritatis? Ab ad animi doloribus dolorum ea eligendi nam, neque officiis pariatur placeat quaerat quibusdam saepe temporibus.
      Asperiores expedita fuga qui quis tempora. Architecto enim eum id modi natus odit, perferendis quam tempora ullam. A ea eius error fugit magni minus
      nostrum nulla quo? Dolorem labore laboriosam modi molestias nulla ratione.
    </p>
  </div>;
};
