import React, {FunctionComponent, useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {IStore} from 'redux/interface';
import {closeArticle} from 'redux/content/actions';
import {getArticle} from 'redux/pageAsyncActions';

import Loader from 'components/loader';
import Article from 'components/article';
import Overlay from 'components/overlay';

import {BotType, IBotAction, IDialog, IXpedition} from 'utils/hooks/use-chat-bot/interfaces';
import {XpeditionItem} from './xpedition/xpedition-item/XpeditionItem';
import ModalWindow from 'components/modal-window';
import {ModalGroup} from './xpedition/modal-group/ModalGroup';
import {LiteCardModel} from './xpedition/lite-card/LiteCardModel';
import CubesImg from 'assets/image/temp/advisor/cubes.png';

import {BotAnswer} from './botAnswer';
import './styles.scss';

const TEMP_CARDS1: LiteCardModel[] = [
  {
    id: 1,
    title: 'Need a Medicare Check-Up',
    imageUrl: '/assets/temp/1.png',
    description: 'Find the best doctor to heal with the knowledge that you are fully covered.'
  },
  {
    id: 2,
    title: 'New to Medicare',
    imageUrl: '/assets/temp/2.png',
    description: 'Find the best doctor to heal with the knowledge that you are fully covered.'
  },
  {
    id: 3,
    title: 'Help me find care.',
    imageUrl: '/assets/temp/3.png',
    description: 'Find the best doctor to heal with the knowledge that you are fully covered.'
  },
  {
    id: 4,
    title: 'Set up my healthcare proxy',
    imageUrl: '/assets/temp/4.png',
    description: 'Find the best doctor to heal with the knowledge that you are fully covered.'
  },
  {
    id: 5,
    title: 'Urgent request (includes triage)',
    imageUrl: '/assets/temp/5.png',
    description: 'Find the best doctor to heal with the knowledge that you are fully covered.'
  }
];

const TEMP_CARDS2: LiteCardModel[] = [
  {
    id: 6,
    title: 'Need a Medicare Check-Up',
    imageUrl: '/assets/temp/6.png',
    description: 'Find the best doctor to heal with the knowledge that you are fully covered.'
  },
  {
    id: 7,
    title: 'New to Medicare',
    imageUrl: '/assets/temp/7.png',
    description: 'Find the best doctor to heal with the knowledge that you are fully covered.'
  },
  {
    id: 8,
    title: 'Help me find care.',
    imageUrl: '/assets/temp/8.png',
    description: 'Find the best doctor to heal with the knowledge that you are fully covered.'
  },
  {
    id: 9,
    title: 'Set up my healthcare proxy',
    imageUrl: '/assets/temp/9.png',
    description: 'Find the best doctor to heal with the knowledge that you are fully covered.'
  },
  {
    id: 10,
    title: 'Urgent request (includes triage)',
    imageUrl: '/assets/temp/10.png',
    description: 'Find the best doctor to heal with the knowledge that you are fully covered.'
  }
];

interface IBotContent {
  botAnswer: BotAnswer[]
  onChange: (message: IBotAction | null, autoSend?: boolean) => void,
  mode: BotType
  xpeditionList: IXpedition[]
  currentDialog: IDialog | null
  onStart: (xpeditionData: {
    botId: number,
    xpedition: IXpedition
  }) => void
  onRemove: (dialogId: number) => void// todo remove
}

export const BotContent: FunctionComponent<IBotContent> = ({
  botAnswer,
  onChange,
  mode,
  xpeditionList,
  currentDialog,
  onStart,
  onRemove
}: IBotContent) => {
  const dispatch = useDispatch();
  const {
    content: {article},
    status: {loading}
  } = useSelector((state: IStore) => state);
  const [pictureAnswer, setPictureAnswer] = useState<BotAnswer[]>([]);
  const [form, setForm] = useState<IBotAction | null>(null);
  const [isModalShow, setIsModalShow] = useState(false);

  useEffect(() => {
    const pictures = botAnswer.filter(item => {
      return !item.getContentId() && item.getPictureUrl();
    });
    const formData = botAnswer.filter(item => item.getExternalComponentsData());

    setPictureAnswer(pictures);
    if (formData.length) {
      setForm(formData[0].getExternalComponentsData());
    } else {
      setForm(null);
    }
  }, [botAnswer]);

  function handleCloseArticle() {
    dispatch(closeArticle());
  }

  function handleOpenModal() {
    setIsModalShow(true);
  }

  function handleCloseModal() {
    setIsModalShow(false);
  }

  function handleChangeOverlay(actionBody: string, autoSend?: boolean) {
    if (actionBody.length === 0) {
      onChange(null);

      return;
    }
    if (form) {
      onChange({
        ...form,
        actionBody
      }, autoSend);
    }
  }

  function handleStartXpedition(xpedition: IXpedition) {
    return (botId: number) => {
      onStart({
        botId,
        xpedition
      });
    };
  }

  // todo remove
  function handleRemoveDialog(dialogId: number) {
    onRemove(dialogId);
  }

  // ----------

  if (mode === BotType.Xpedition) {
    const isOpenXpedition = xpeditionList.find((xpedition) => xpedition.id === currentDialog?.xpeditionId);

    if (!isOpenXpedition) {
      return <>
        {
          xpeditionList.map(item => {
            return <XpeditionItem
              key={item.id}
              xpedition={item}
              onStart={handleStartXpedition(item)}
              onRemove={handleRemoveDialog}// todo remove
            />;
          })
        }
        {/*<div className="xpedition-item">*/}
        {/*  <div className="xpedition-item__header">*/}
        {/*    <h2 className="xpedition-item__title">*/}
        {/*      I need...*/}
        {/*    </h2>*/}
        {/*  </div>*/}
        {/*  <div className="xpedition-item__body">*/}
        {/*    <div className="xpedition-item__image-wrapper">*/}
        {/*      <img*/}
        {/*        className="xpedition-item__image"*/}
        {/*        src={CubesImg}*/}
        {/*        alt='I need'*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*    <div className="xpedition-item__description">*/}
        {/*      <p><strong>...and we here to help.</strong> Specialists,caregivers, medications can be find through our database -*/}
        {/*        to name few. And we will make sure that you are covered.</p>*/}
        {/*    </div>*/}
        {/*    <div className="xpedition-item__button-wrapper">*/}
        {/*      <button*/}
        {/*        className="xpedition-item__button xpedition-item__view-button"*/}
        {/*        onClick={handleOpenModal}*/}
        {/*      >*/}
        {/*        <span className="xpedition-item__button-label">View</span>*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <ModalWindow
          show={isModalShow}
          className="xpedition__modal"
          onHide={handleCloseModal}
        >
          <div className="modal-content">
            <ModalGroup title="What is your NEED?" cards={TEMP_CARDS1} />
            <ModalGroup title="We got the plan." cards={TEMP_CARDS2} />
          </div>
        </ModalWindow>
      </>;
    }
  }
  if (form) {
    console.log(form);

    return <>
      <div className="bot-chat__toolbar bot-chat__toolbar--align-left">
        {form.parsedActionBody?.addActionBody.form?.prompt}
      </div>
      <Overlay
        showInDialog={false}
        overlay={form}
        onChange={handleChangeOverlay}
      />
    </>;
  }
  if (pictureAnswer.length) {
    return <>
      {
        pictureAnswer
          .map(answer => {
            return <div
              key={answer.getMessageId()}
            >
              <div className="bot-chat__toolbar bot-chat__title  bot-chat__toolbar--align-left">
                {answer.getText()}
              </div>
              <div className="bot-chat__picture-wrapper">
                {answer.getPictureUrl()
                  ? <img src={answer.getPictureUrl()} role="presentation" alt="" className="bot-chat__picture"/>
                  : <Loader className="bot-chat__picture-loader" nested/>}
              </div>
            </div>;
          })
      }
    </>;
  }

  return <>
    {loading.includes(getArticle) && <Loader nested/>}
    {
      !loading.includes(getArticle) && article
        ? <Article card={article} onClose={handleCloseArticle}/>
        : <div className="bot__toolbar">
        </div>
    }
  </>;
};

