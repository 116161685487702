import React, {FunctionComponent, useEffect} from 'react';
import {IOverlayInput} from 'components/overlay/interfaces';

import './styles.scss';
import {CalendarPickerView, DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {TextField} from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';

interface IDDatepicker {
  control: IOverlayInput
  onChange: (value: string) => void
  onPressEnter: () => void
}

export const DDatepicker: FunctionComponent<IDDatepicker> = ({control, onChange, onPressEnter}: IDDatepicker) => {
  const [value, setValue] = React.useState<Dayjs | null>(null);

  function handleChangeDate(date: Dayjs | null) {
    setValue(date);
    const formattedDate = date?.format(control.datePicker?.format);

    onChange(formattedDate || '');
  }

  function parseDate(date: string) {
    return dayjs(date, control.datePicker?.format);
  }

  useEffect(() => {
    if (control.value) {
      setValue(parseDate(control.value));
    }
  }, []);

  function parseStartView(startView: string) {
    const startViewMap: { [key: string]: CalendarPickerView } = {
      YEAR: 'year',
      MONTH: 'month',
      DAY: 'day'
    };

    return startViewMap[startView];
  }

  const keydownHandler = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onPressEnter();
    }
  };

  return <div className="d-datepicker">
    {control.datePicker
      ? <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={control.datePicker?.locale}>
        <DatePicker
          views={['year', 'month', 'day']}
          label={control.label}
          openTo={parseStartView(control.datePicker?.startView)}
          inputFormat={control.datePicker?.format}
          value={value}
          defaultCalendarMonth={control.datePicker?.startDate ? parseDate(control.datePicker?.startDate) : undefined}
          minDate={parseDate(control.datePicker?.minDate)}
          maxDate={parseDate(control.datePicker?.maxDate)}
          onChange={(newValue: Dayjs | null) => {
            handleChangeDate(newValue);
          }}
          renderInput={(params) => <TextField {...params} onKeyDown={keydownHandler}/>}
        />
      </LocalizationProvider>
      : <></>}
  </div>;
};
