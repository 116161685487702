import React, {useEffect, useState, ChangeEvent} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';

import GritxButton from 'components/gritx-button';
import GritxSelect from 'components/gritx-select';
import EmailZone from 'components/еmail-zone';
import {ButtonVariant} from 'components/gritx-button/ButtonVariantEnum';

import {getTemplates, materialsAsyncActions} from 'redux/pageAsyncActions';
import {IStore} from 'redux/interface';
import {setSendMaterialsStatus} from 'redux/content/actions';

import './styles.scss';

interface ISubject {
  id: number
  name: string
  code: string
  template: string
  language: string
}

export interface IShareMaterials {
  templateId: number | undefined
  toSelf: boolean
  emails: string
}

interface IMaterialsShare {
  onSend: () => void
  onChange: (data: IShareMaterials) => void
  onRemoveAll: () => void,
  onShareMode: (sharing: boolean) => void,
  isDisabledRemoveAll: boolean
  isDisabledShare: boolean
  isDisabledSend: boolean
}

export const MaterialsShare: React.FunctionComponent<IMaterialsShare> = (
  {
    onSend,
    onRemoveAll,
    onShareMode,
    isDisabledRemoveAll,
    isDisabledShare,
    isDisabledSend,
    onChange
  }: IMaterialsShare
) => {
  const {
    content: {
      shareTemplates,
      sendMaterialsStatus
    }
  } = useSelector((state: IStore) => state);
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [toSelf, setToSelf] = useState(false);
  const [emails, setEmails] = useState('');
  const [subject, setSubject] = useState<ISubject | null>(null);

  useEffect(() => {
    dispatch(materialsAsyncActions[getTemplates]());
  }, []);

  useEffect(() => {
    setIsOpen(false);
    setEmails('');
    setSubject(null);
    setToSelf(false);
  }, [sendMaterialsStatus]);

  useEffect(() => {
    onChange({
      emails,
      templateId: subject?.id,
      toSelf
    });
  }, [toSelf, emails, subject]);

  useEffect(() => {
    function handleCloseTab(e: BeforeUnloadEvent) {
      if (isOpen) {
        const message = intl.formatMessage({
          id: 'common.notification.close-tab',
          defaultMessage: 'Changes you made may not be saved.'
        });

        (e || window.event).returnValue = message;

        return message;
      }

      return null;
    }

    window.addEventListener('beforeunload', handleCloseTab);

    return () => window.removeEventListener('beforeunload', handleCloseTab);
  }, [isOpen]);

  function handleClearMaterials() {
    onRemoveAll();
  }

  function handleToggleForm(value: boolean) {
    return () => {
      setIsOpen(value);
      onShareMode(value);
      if (!value) {
        setSubject(null);
        setEmails('');
        setToSelf(false);
      }
    };
  }

  function handleSendForm() {
    onSend();
  }

  function handleSelectSubject(e: ChangeEvent<HTMLSelectElement>) {
    const newSubj = shareTemplates.find(subj => subj.id.toString() === e.target.value);

    if (newSubj) {
      setSubject(newSubj);
    }
  }

  function handleDone() {
    setSubject(null);
    dispatch(setSendMaterialsStatus(false));
  }

  function updateEmailsAndToSelf(parsedEmails: string[], parsedToSelf: boolean) {
    setEmails(parsedEmails.join(','));
    setToSelf(parsedToSelf);
  }

  function renderHeader() {
    if (sendMaterialsStatus) {
      return <GritxButton
        title={intl.formatMessage({
          id: 'common.button.done',
          defaultMessage: 'Done'
        })}
        className={'materials-share__button'}
        variant={ButtonVariant.Primary}
        onClick={handleDone}
      />;
    }

    return isOpen ? <>
      <GritxButton
        title={intl.formatMessage({
          id: 'common.button.cancel',
          defaultMessage: 'Cancel'
        })}
        className={'materials-share__button'}
        variant={ButtonVariant.Light}
        onClick={handleToggleForm(false)}
      />
      <GritxButton
        title={intl.formatMessage({
          id: 'common.button.send',
          defaultMessage: 'Send'
        })}
        className={'materials-share__button'}
        variant={ButtonVariant.Primary}
        disabled={isDisabledSend}
        onClick={handleSendForm}
      />
    </>
      : <>
        <GritxButton
          title={intl.formatMessage({
            id: 'materials.removeAll',
            defaultMessage: 'Remove All Cards'
          })}
          className={'materials-share__button'}
          variant={ButtonVariant.Light}
          disabled={isDisabledRemoveAll}
          onClick={handleClearMaterials}
        />
        <GritxButton
          title={intl.formatMessage({
            id: 'materials.create',
            defaultMessage: 'Create Document Package'
          })}
          className={'materials-share__button materials-share__create-button'}
          variant={ButtonVariant.Primary}
          disabled={isDisabledShare}
          onClick={handleToggleForm(true)}
        />
      </>;
  }

  return <div className='materials-share'>
    <div className="materials-share__header">
      {renderHeader()}
    </div>
    {
      isOpen
      && <div className='materials-share__body'>
        <div className="materials-share__subject subject-selector">
          <GritxSelect
            name={'subject'}
            options={shareTemplates}
            placeholder={intl.formatMessage({
              id: 'materials.subject',
              defaultMessage: 'Choose the SUBJECT'
            })}
            defaultValue={'placeholder'}
            onChange={handleSelectSubject}
            onBlur={handleSelectSubject}
          />
          <div
            className='subject-selector__description'
            dangerouslySetInnerHTML={{
              __html: subject ? subject.template : ''
            }}
          />
        </div>
        <div className="materials-share__form">
          <EmailZone
            initialEmails={emails}
            initialToSelf={toSelf}
            onChange={(parsedEmails, parsedToSelf) => updateEmailsAndToSelf(parsedEmails, parsedToSelf)}/>
        </div>
      </div>
    }
    {
      isOpen && <h2 className="materials-share__cards-title">
        <FormattedMessage
          id={'materials.prompt'}
          defaultMessage={'Click on the cards that you want to send'}
        />
      </h2>
    }
  </div>;
};
