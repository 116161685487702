import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';

import {IStore} from 'redux/interface';
import {IArticle} from 'redux/content/interfaces';
import {addFavoriteCardAction, addMaterialAction, removeFavoriteCardAction, removeMaterialAction} from 'redux/content/contentAsyncActions';

import GritxButton from 'components/gritx-button';
import {ButtonVariant} from 'components/gritx-button/ButtonVariantEnum';
import Meditation from 'components/meditation';
import Journal from 'components/journal';
import SelfInterview from 'components/self-interview';

import {ContentType} from 'utils/constants/constants';

import {ReactComponent as FullHeart} from 'assets/image/library/full-heart.svg';
import {ReactComponent as EmptyHeart} from 'assets/image/library/empty-heart.svg';
import Clock from 'assets/image/library/clock.svg';
import Book from 'assets/image/library/defaultCardImg.png';

import './styles.scss';

interface IContentLayout {
  card: IArticle
  onClose?: () => void
}

export const Article: React.FunctionComponent<IContentLayout> = ({
  card,
  onClose
}: IContentLayout) => {
  const {
    auth: {auth0User}
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const intl = useIntl();
  const proFlag = auth0User?.isPro || false;
  const [isAddedToMaterials, setIsAddedToMaterials] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isShowJournal, setIsShowJournal] = useState(false);
  const [isShowInterview, setIsShowInterview] = useState(false);
  const [journalPage, setJournalPage] = useState<null | number>(null);

  useEffect(() => {
    setIsAddedToMaterials(card.material);
    setIsFavorite(card.favorite);
  }, [card]);

  useEffect(() => {
    setIsShowJournal(false);
  }, [card.id]);

  function addToMaterials() {
    setIsAddedToMaterials(!isAddedToMaterials);
    if (isAddedToMaterials) {
      dispatch(removeMaterialAction([card.id]));
    } else {
      dispatch(addMaterialAction(card.id));
    }
  }

  function changeFavorite() {
    setIsFavorite(!isFavorite);
    if (isFavorite) {
      dispatch(removeFavoriteCardAction(card.id));
    } else {
      dispatch(addFavoriteCardAction(card.id));
    }
  }

  function handleClose() {
    if (onClose) {
      if (isShowJournal) {
        if (confirm(intl.formatMessage({
          id: 'common.notification.close-tab',
          defaultMessage: 'Changes you made may not be saved.'
        }))) {
          onClose();
        }

        return;
      }
      onClose();
    }
  }

  function handleShowJournal(val: boolean) {
    return () => setIsShowJournal(val);
  }

  function handleChangeJournalPage(page: number | null) {
    if (typeof page === 'number') {
      setJournalPage(page + 1);
    } else {
      setJournalPage(page);
    }
  }

  function handleStartInterview() {
    setIsShowInterview(true);
  }

  function handleFinishInterview() {
    setIsShowInterview(false);
  }

  const renderAddToMaterialsButton = () => (proFlag
    && <GritxButton
      title={isAddedToMaterials
        ? intl.formatMessage({
          id: 'library.card.saved',
          defaultMessage: 'Saved'
        })
        : intl.formatMessage({
          id: 'library.card.addToMaterials',
          defaultMessage: 'Add to records'
        })}
      variant={ButtonVariant.Primary}
      onClick={addToMaterials}
    />);

  const renderMeditation = () => (card.type === ContentType.Meditation
    && <Meditation card={card}/>);
  const renderJournal = () => (card.type === ContentType.Journal
    && <Journal
      card={card}
      onStart={handleShowJournal(true)}
      onChangePage={handleChangeJournalPage}
      onStop={handleShowJournal(false)}
    />);
  const renderSelfInterview = () => (card.type === ContentType.SelfInterview
    && <SelfInterview
      card={card}
      onStart={handleStartInterview}
      onFinish={handleFinishInterview}
    />);

  const renderArticleInformation = () => (isShowJournal
    ? journalPage
    && <p className={'article__page-number'}>
      <FormattedMessage
        id={'library.page'}
        defaultMessage={'Page {page}'}
        values={{page: journalPage}}
      />
    </p>
    : <>
      <div className="article__icon">
        <img
          className="article__icon-img"
          src={card.labelPictureUrl}
          alt={card.labelText}
          title={card.labelText}
        />
      </div>
      <div
        className="article__icon article__icon--reading-time"
        style={{backgroundImage: `url('${card.readingImageUrl || Clock}')`}}
        title={card.readingName}
      />
    </>);

  return <div className="article">
    <div className="article__header">
      <div className="article__title">
        <h2 className="article__heading">{card.title}</h2>
        {
          onClose
          && <button className="article__close-button" onClick={handleClose}>X</button>
        }
      </div>
      {/* <div className="article__props">*/}
      {/*  <div className="article__information">*/}
      {/*    {renderArticleInformation()}*/}
      {/*  </div>*/}
      {/*  <div className="article__actions">*/}
      {/*    {renderAddToMaterialsButton()}*/}
      {/*    <button className="article__button-icon" onClick={changeFavorite}>*/}
      {/*      <div className="article__icon">*/}
      {/*        {*/}
      {/*          isFavorite*/}
      {/*            ? <FullHeart className="article__icon-img"/>*/}
      {/*            : <EmptyHeart className="article__icon-img"/>*/}
      {/*        }*/}
      {/*      </div>*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/* </div>*/}
    </div>
    {
      !isShowJournal && !isShowInterview
      && <div className="article__body">
        <p className="article__description">
          {card.description}
        </p>
        {
          card.pictureUrl && <div className="article__image-wrapper">
            {
              <img src={card.pictureUrl} alt="" className="article__image"/>
            }
          </div>
        }
        {
          card.text
          && <div
            className="article__content"
            dangerouslySetInnerHTML={{
              __html: card.text
            }}
          />
        }
      </div>
    }
    {renderMeditation()}
    {renderJournal()}
    {renderSelfInterview()}
  </div>;
};
