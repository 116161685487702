import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {IStore} from 'redux/interface';

import AdditionalInformation from 'components/additional-information';
import ConnectLine from 'components/connect-line';

import './styles.scss';

type Props = {
  hideSeparator?: boolean;
}

export const Footer = ({hideSeparator}: Props): JSX.Element => {
  const {
    translation: {
      locale,
      translations
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();

  useGetTranslations({
    locale,
    translations,
    dispatch,
    translationKey: 'footer'
  });

  return <footer className={'footer'}>
    {/* <ConnectLine/>*/}
    <AdditionalInformation/>
  </footer>;
};
