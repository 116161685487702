import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import {Avatar} from 'components/avatar/Avatar';
import {ButtonVariant} from 'components/gritx-button/ButtonVariantEnum';
import {GritxButton} from 'components/gritx-button/GritxButton';
import {Input} from 'components/input/Input';
import {ModalWindow} from 'components/modal-window/ModalWindow';
import {AvatarList} from 'components/avatar-list/AvatarsList';
import {InputPhone} from 'components/input-phone/InputPhone';

import {IUserProfile} from 'redux/auth/interfaces';
import {IStore} from 'redux/interface';
import {getProfileAvatar, logoutAction, updateUserProfileAction} from 'redux/auth/authAsyncActions';
import {changeLocale} from 'redux/translation/translationAsyncActions';

import {isEmailValidator} from 'utils/helpers/validators';
import {languages} from 'utils/constants/constants';
import {useLoadFile} from 'utils/hooks/useLoadFile';
import {useChatBot} from 'utils/hooks/use-chat-bot/useChatBot';
import {BotType} from 'utils/hooks/use-chat-bot/interfaces';

import UserIcon from 'assets/image/user-settings/user-ico.svg';

export const SettingsForm = (): JSX.Element => {
  const {
    auth: {
      userProfile,
      profileAvatar
    }
  } = useSelector((state: IStore) => state);

  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    getFileUrl
  } = useLoadFile();

  useEffect(() => {
    dispatch(getProfileAvatar());
  }, []);
  const {
    dialogMethods: {setDialogLanguage}
  } = useChatBot(BotType.Chat);
  const [isInputChange, setIsInputChange] = useState(false);
  const [isRequiredEmail, setIsRequiredEmail] = useState(false);
  const [modalAvatarsFlag, setModalAvatarsFlag] = useState<null | 'user' | 'bot'>(null);
  const [profile, setProfile] = useState<IUserProfile>(userProfile || {} as IUserProfile);

  useEffect(() => {
    if (userProfile) {
      setProfile(userProfile);
    }
  }, [userProfile]);

  useEffect(() => {
    if (userProfile?.email) {
      setIsRequiredEmail(true);
      setIsInputChange(false);
    }
  }, [userProfile?.email]);

  function handleChangeInput(key: string) {
    return (value: string) => {
      const newProfile = {
        ...profile,
        [key]: value
      };
      const isValidEmail = () => (newProfile.email.length >= 1 && !isEmailValidator(newProfile.email)) || (isRequiredEmail && newProfile.email.length === 0);
      const isFieldsCongruence = Object.keys(profile)
        .reduce((acc, item) => {
          if (userProfile) {
            const res = userProfile[item] !== newProfile[item];

            return acc || res;
          }

          return acc;
        }, false);

      setIsInputChange(isFieldsCongruence);

      if (isValidEmail()) {
        setIsInputChange(false);
      }

      if (newProfile.firstName.length === 0 || newProfile.lastName.length === 0) {
        setIsInputChange(false);
      }

      if (key === 'phone') {
        setIsInputChange(false);
      }

      setProfile({
        ...newProfile,
        [key]: value
      });

      if (key === 'phone' && !value) {
        setProfile({
          ...newProfile,
          phone: ''
        });
        setIsInputChange(false);
      }
    };
  }

  function handleChangeLang(lang: string) {
    return () => {
      const newProfile = {
        ...profile,
        language: lang
      };

      setProfile(newProfile);
      dispatch(changeLocale(lang));
      dispatch(updateUserProfileAction(newProfile));
      setDialogLanguage(lang);
    };
  }

  function handleSave() {
    if (profile) {
      dispatch(updateUserProfileAction(profile));
    }
    setIsInputChange(false);
  }

  function handleSignOut() {
    dispatch(logoutAction());
  }

  function handleCloseModal() {
    setModalAvatarsFlag(null);
  }

  function openModal(type: 'user' | 'bot') {
    return () => setModalAvatarsFlag(type);
  }

  return (
    <div className="settings__content">
      <div className="settings__avatars">
        <div className="avatar">
          <Avatar
            editable
            handleClick={openModal('user')}
            imgUrl={profileAvatar?.value ? getFileUrl(profileAvatar.value) : UserIcon}
          />
          <p className="avatar__text">
            <FormattedMessage
              id="user-settings.avatarUser"
              defaultMessage="Me"
            />
          </p>
        </div>
        <div className="avatar">
          <Avatar
            editable
            handleClick={openModal('bot')}
            imgUrl={UserIcon}
          />
          <p className="avatar__text avatar__text-bot">
            <FormattedMessage
              id="user-settings.avatarBot"
              defaultMessage="MY GritX"
            />
          </p>
        </div>
      </div>
      <div className="settings__info info">
        <div className="info__wrap">
          <div className="info__language">
            {
              languages.map((item, index) => {
                return (
                  <GritxButton
                    key={index}
                    title={item.title}
                    variant={profile.language === item.value
                      ? ButtonVariant.Primary
                      : ButtonVariant.Outline
                    }
                    onClick={handleChangeLang(item.value)}
                    className="info__language-btn"
                  />
                );
              })
            }
          </div>
          <div className="info__content">
            <Input
              className="info__item"
              label={intl.formatMessage({
                id: 'user-settings.firstName',
                defaultMessage: 'First Name'
              })}
              required
              maxLength={100}
              type="text"
              placeholder={intl.formatMessage(({
                id: 'user-settings.firstName.placeholder',
                defaultMessage: 'Please, enter your First Name'
              }))}
              value={profile.firstName}
              onChange={handleChangeInput('firstName')}
            />
            <Input
              className="info__item"
              label={intl.formatMessage({
                id: 'user-settings.lastName',
                defaultMessage: 'Last Name'
              })}
              required
              maxLength={100}
              type="text"
              placeholder={intl.formatMessage(({
                id: 'user-settings.lastName.placeholder',
                defaultMessage: 'Please, enter your Last Name'
              }))}
              value={profile.lastName}
              onChange={handleChangeInput('lastName')}
            />
            <Input
              className="info__item"
              label={intl.formatMessage({
                id: 'user-settings.email',
                defaultMessage: 'Email'
              })}
              required={isRequiredEmail}
              type="email"
              placeholder={intl.formatMessage(({
                id: 'user-settings.email.placeholder',
                defaultMessage: 'Please, enter your Email'
              }))}
              value={profile.email}
              onChange={handleChangeInput('email')}
            />
            <InputPhone
              labelClassName="info__phone"
              inputPhoneValue={profile.phone}
              onChangeValue={handleChangeInput('phone')}
              isPhoneChanged={setIsInputChange}
            />
            <div className="settings__save">
              <GritxButton
                className="settings__save-btn"
                title={intl.formatMessage({
                  id: 'user-settings.buttonSave',
                  defaultMessage: 'Save'
                })}
                disabled={!isInputChange}
                variant={ButtonVariant.Primary}
                onClick={handleSave}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="settings__log-out">
        <GritxButton
          className="settings__log-out-btn"
          title={intl.formatMessage({
            id: 'user-settings.buttonLogOut',
            defaultMessage: 'Log Out'
          })}
          variant={ButtonVariant.Outline}
          onClick={handleSignOut}
        />
      </div>
      <ModalWindow
        className="settings__modal"
        show={Boolean(modalAvatarsFlag)}
        onHide={handleCloseModal}>
        <AvatarList type={modalAvatarsFlag}/>
      </ModalWindow>
    </div>
  );
};
