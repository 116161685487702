import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Redirect} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';

import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {IStore} from 'redux/interface';
import {getMaterialsContent, getTranslations, materialsAsyncActions, sendMaterials} from 'redux/pageAsyncActions';
import {removeMaterialAction} from 'redux/content/contentAsyncActions';
import {IArticleCard} from 'redux/content/interfaces';

import NotFoundImg from 'assets/image/not-found/not-found-404.png';
import SuccessImg from 'assets/image/materials/success.png';

import CardSection from 'components/card-section';
import Loader from 'components/loader';
import NotFoundInfo from 'components/not-found-info';
import {IShareMaterials} from 'components/materials-share/MaterialsShare';
import MaterialsShare from 'components/materials-share';

import './styles.scss';

export const Materials: React.FunctionComponent = () => {
  const {
    auth: {auth0User},
    translation: {
      locale,
      translations
    },
    content: {
      materialsContent,
      sendMaterialsStatus
    },
    status: {
      loading
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const [isLoadCards, setIsLoadCards] = useState(true);
  const [isShareMode, setIsShareMode] = useState(false);
  const [checkedCards, setCheckedCards] = useState<number[]>([]);
  const [recipientInfo, setRecipientInfo] = useState<IShareMaterials>({
    emails: '',
    templateId: 0,
    toSelf: false
  });

  useGetTranslations({
    locale,
    translations,
    dispatch,
    translationKey: 'materials'
  });

  useEffect(() => {
    dispatch(materialsAsyncActions[getMaterialsContent]());
    setIsLoadCards(false);
  }, []);

  useEffect(() => {
    setCheckedCards([]);
    setIsShareMode(false);
  }, [sendMaterialsStatus]);

  useEffect(() => {
    if (!isShareMode) {
      setCheckedCards([]);
      setRecipientInfo({
        emails: '',
        templateId: 0,
        toSelf: false
      });
    }
  }, [isShareMode]);

  if (!auth0User?.isPro) {
    return <Redirect to="*"/>;
  }

  function removeAll() {
    const cardsId: number[] = [];

    materialsContent.map((card: IArticleCard) => {
      return cardsId.push(card.id);
    });
    dispatch(removeMaterialAction(cardsId, true));
  }

  function validateModel(): boolean {
    return Boolean(checkedCards.length && recipientInfo.emails && recipientInfo.templateId);
  }

  function handleSendMaterials() {
    if (validateModel()) {
      dispatch(materialsAsyncActions[sendMaterials]({
        contentIds: checkedCards,
        emails: recipientInfo.emails,
        templateId: recipientInfo.templateId as number,
        toSelf: recipientInfo.toSelf
      }));
    }
  }

  function handleChangeShareMode(mode: boolean) {
    setIsShareMode(mode);
  }

  function handleChangeShareMaterials(materialsModel: IShareMaterials) {
    setRecipientInfo(materialsModel);
  }

  function handleOpenCard(cardId: number) {
    if (isShareMode) {
      if (checkedCards.includes(cardId)) {
        setCheckedCards(checkedCards.filter(item => item !== cardId));
      } else {
        setCheckedCards([...checkedCards, cardId]);
      }
    } else {
      history.push(
        `/content#${cardId}`,
        history.location.state ? history.location.state : {parentLocation: history.location.pathname}
      );
    }
  }

  function renderMaterialsCards() {
    return materialsContent.length === 0
      ? <>
        <NotFoundInfo
          title={intl.formatMessage({
            id: 'materials.notFound',
            defaultMessage: 'All your stored information will go here'
          })}
          image={NotFoundImg}
        />
      </>
      : <>
        <CardSection
          cards={isShareMode ? materialsContent.filter(item => item.material) : materialsContent}
          disabledButtons={isShareMode}
          checkedCards={checkedCards}
          onClickCard={handleOpenCard}
        />
      </>;
  }

  return <div className="materials container">
    {
      !loading.includes(getTranslations) && <>
        {loading.includes(sendMaterials) && <Loader nested/>}
        <h1 className="materials__title">
          <FormattedMessage
            id="materials.title"
            defaultMessage="Your records in one place"
          />
        </h1>
        <div className="materials__share-line">
          <MaterialsShare
            isDisabledRemoveAll={!materialsContent.length}
            isDisabledShare={!materialsContent.length}
            isDisabledSend={!validateModel()}
            onSend={handleSendMaterials}
            onChange={handleChangeShareMaterials}
            onRemoveAll={removeAll}
            onShareMode={handleChangeShareMode}
          />

        </div>
        {
          sendMaterialsStatus
            ? <div className="materials__done">
              <div className="materials__image-wrapper">
                <img className="materials__image" src={SuccessImg} alt="send success"/>
              </div>
              <p className="materials__done-text">
                <FormattedMessage
                  id="materials.success"
                  defaultMessage="Your records were successfully sent!"
                />
              </p>
            </div>
            : <div className="materials__cards">
              {
                loading.includes(getMaterialsContent) || isLoadCards ? <Loader nested/> : renderMaterialsCards()}
            </div>
        }
      </>
    }
  </div>;
};
