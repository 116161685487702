import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';

import {IStore} from 'redux/interface';
import {changeLocale, loadTranslation} from 'redux/translation/translationAsyncActions';

import {languages, routeTranslationKeys, RouteVar} from 'utils/constants/constants';

import '../styles.scss';

export const LanguageMenu = (): JSX.Element => {
  const [isOpenLanguageMenu, setIsOpenLanguageMenu] = useState(false);
  const dispatch = useDispatch();

  const {
    translation: {locale}
  } = useSelector((state: IStore) => state);

  function handleChangeLocale(value: string) {
    return () => {
      if (isOpenLanguageMenu) {
        setIsOpenLanguageMenu(false);
      } else {
        setIsOpenLanguageMenu(true);
      }
      dispatch(changeLocale(value));
    };
  }

  const sortLang = languages.reduce<{
    value: string;
    title: string;
    key: string;
  }[]>((acc, item) => {
    if (item.value === locale) {
      return [item, ...acc];
    }
    acc.push(item);

    return acc;
  }, []);

  return <div className="header__language">
    <div className="header__language-wrapper">
      <div
        className={cn('language', {
          'language--open': isOpenLanguageMenu
        })}
      >
        {
          sortLang
            .map((lang, i) => {
              return <button
                key={i}
                className={'language__title'}
                onClick={handleChangeLocale(lang.value)}
              >{lang.title}</button>;
            })
        }
      </div>
    </div>
  </div>;
};

const navItems = [
  {
    index: 2,
    path: RouteVar.Xpedition,
    title: 'Advisor',
    image: undefined,
    isProUser: false
  // },
  // {
  //   index: 3,
  //   path: RouteVar.Backpack,
  //   title: 'ToDo',
  //   image: undefined,
  //   isProUser: false
  // },
  // {
  //   index: 4,
  //   path: RouteVar.Materials,
  //   title: 'Records',
  //   image: undefined,
  //   isProUser: false
  // },
  // {
  //   index: 5,
  //   path: RouteVar.Library,
  //   title: 'Search',
  //   image: undefined,
  //   isProUser: false
  }
];

interface INavigationProps {
  className?: string;
  proUser?: boolean;
  onOpenMenu: () => void;
}

export const Navigation = ({
  className,
  proUser,
  onOpenMenu
}: INavigationProps): JSX.Element => {
  const {
    translationKeys
  } = useSelector((state: IStore) => state.translation);

  const dispatch = useDispatch();

  function handleMenuItemClick(route: string) {
    return () => {
      if (!translationKeys.includes(routeTranslationKeys[route])) {
        dispatch(loadTranslation(routeTranslationKeys[route]));
      }
      onOpenMenu();
    };
  }

  return (<nav className={cn('nav', className)}>
    {
      navItems.map((item, index) => {
        if (!proUser && item.isProUser) {
          return null;
        }

        return (<div key={index} className="nav__item">
          <NavLink
            to={item.path}
            className={'nav__link'}
            activeClassName={'nav__link-active'}
            onClick={handleMenuItemClick(item.path)}
          >
            <div className={'nav__item-wrapper'}>
              <div className={'nav__img-wrapper'}>
                {item.image && <img className={'nav__img'} src={item.image} alt={item.title}/>}
              </div>
              <p className={'nav__item-text'}>
                <FormattedMessage
                  id={`common.navigation.${item.title}`}
                  defaultMessage={item.title}
                />
              </p>
            </div>
          </NavLink>
        </div>);
      })
    }
  </nav>);
};
