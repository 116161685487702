import React, {FunctionComponent, useEffect, useState} from 'react';
import {IOverlayInput} from 'components/overlay/interfaces';

import './styles.scss';
import {FormattedMessage} from 'react-intl';

interface IDChoice {
  control: IOverlayInput
  onChange: (value: string) => void
}

export const DChoice: FunctionComponent<IDChoice> = ({
  control,
  onChange
}: IDChoice) => {
  const [choiceIdx, setChoiceIdx] = useState(0);

  useEffect(() => {
    if (control.list) {
      onChange(control.list[choiceIdx].value);
    }
  }, []);

  function handleChangeChoice() {
    if (control.list) {
      const newIdx = choiceIdx + 1 < control.list.length ? choiceIdx + 1 : 0;

      setChoiceIdx(newIdx);
      onChange(control.list[newIdx].value);
    }
  }

  if (!control.list) {
    return <div>
      <FormattedMessage
        id={'common.choice.error'}
        defaultMessage={'Incorrect data'}
      />
    </div>;
  }

  return <button className="d-choice" onClick={handleChangeChoice}>
    {control.list[choiceIdx].label}
  </button>;
};
