import React, {FunctionComponent} from 'react';
import {FormattedMessage} from 'react-intl';

import './styles.scss';

export const Privacy: FunctionComponent = () => {
  return <div className="privacy">
    <header className="privacy__title-window">
      <FormattedMessage
        id={'privacy.titlePrivacy'}
        defaultMessage={'Privacy Policy'}
      />
    </header>
    <p className="privacy__contents">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque commodi eos quos repudiandae. Ad aliquam amet asperiores atque consequatur deleniti
      distinctio dolorem doloremque ea est et ex fugiat illum labore laudantium minus mollitia necessitatibus non obcaecati omnis pariatur perferendis
      praesentium provident quaerat, quas qui quia quibusdam recusandae repellendus unde ut veniam vero, voluptatum! A ad adipisci, amet asperiores aspernatur
      consequatur cupiditate debitis dolores ea earum excepturi id neque nisi nobis odio officiis omnis perferendis praesentium quae quia ratione rem
      repudiandae suscipit, tenetur voluptate voluptates voluptatibus. Consectetur id maxime modi quibusdam vero. Aperiam dolorum repellat tempore temporibus!
      Ab accusamus aperiam architecto corporis deserunt dignissimos dolor eaque eius eligendi et explicabo fugiat hic mollitia, nesciunt nulla numquam omnis
      praesentium quas quod rerum sed sit suscipit tenetur veritatis vitae voluptate voluptatibus! Enim expedita maiores quam veritatis! Dolorem expedita
      incidunt laudantium nihil pariatur perspiciatis provident ratione? Accusantium amet architecto aut blanditiis consequuntur corporis dolorum earum esse
      eveniet harum hic ipsa ipsam laborum, laudantium maxime minima molestias natus nulla optio perferendis possimus quidem ratione recusandae reiciendis
      sapiente soluta temporibus vero voluptatem voluptates voluptatum! Consequatur dignissimos distinctio dolor eaque earum ex harum iure magni maxime nesciunt
      non perspiciatis quis, recusandae reiciendis reprehenderit sint voluptas. Earum, libero.
    </p>
  </div>;
};
