import React, {FunctionComponent} from 'react';
import {LiteCard} from '../lite-card/LiteCard';
import {LiteCardModel} from '../lite-card/LiteCardModel';

import './styles.scss';

interface IModalGroup {
  title: string
  cards: LiteCardModel[]
}

export const ModalGroup: FunctionComponent<IModalGroup> = ({
  title,
  cards
}: IModalGroup) => {
  return <div className="modal-group">
    <h2 className="modal-group__title">{title}</h2>
    <div className="modal-group__cards">
      {cards.map(card =><LiteCard key={card.id} id={card.id} title={card.title} imageUrl={card.imageUrl} description={card.description}/>)}
    </div>
  </div>;
};
