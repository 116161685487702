import React, {FunctionComponent} from 'react';
import {FormattedMessage} from 'react-intl';
import {IXpedition} from 'utils/hooks/use-chat-bot/interfaces';

import GritxButton from 'components/gritx-button';
import {ButtonVariant} from 'components/gritx-button/ButtonVariantEnum';

import './styles.scss';

interface IXpeditionItem {
  xpedition: IXpedition
  onStart: (botId: number) => void
  onRemove: (dialogId: number) => void// todo remove
}

export const XpeditionItem: FunctionComponent<IXpeditionItem> = ({
  xpedition,
  onStart,
  onRemove
}: IXpeditionItem) => {
  function handleStartXpedition(botId: number) {
    return () => {
      onStart(botId);
    };
  }

  // todo remove
  function handleRemoveDialog(item: IXpedition) {
    return () => {
      if (item.dialogId) {
        onRemove(item.dialogId);
      }
    };
  }

  // ----------

  return <div className="xpedition-item">
    <div className="xpedition-item__header">
      <h2 className="xpedition-item__title">
        {xpedition.theme}
      </h2>
    </div>
    <div className="xpedition-item__body">
      <div className="xpedition-item__image-wrapper">
        <img
          className="xpedition-item__image"
          src={xpedition.imageUrl}
          alt={xpedition.theme}
        />
      </div>
      <div className="xpedition-item__description">
        <p>{xpedition.description}</p>
      </div>
      <div className="xpedition-item__button-wrapper">
        <button
          className="xpedition-item__button"
          onClick={handleStartXpedition(xpedition.id)}
        >
          <span className="xpedition-item__button-label">
            {xpedition.dialogId && xpedition.percentage === 100 && <FormattedMessage id={'common.button.done'} defaultMessage={'Done'}/>}
            {xpedition.dialogId && xpedition.percentage !== 100 && <FormattedMessage id={'common.button.continue'} defaultMessage={'Continue'}/>}
            {!xpedition.dialogId && <FormattedMessage id={'common.button.start'} defaultMessage={'Start'}/>}
          </span>
        </button>
        {
          xpedition.dialogId && <>
            <GritxButton
              className="xpedition-item__button--remove"
              onClick={handleRemoveDialog(xpedition)}
              title={'Remove'}
              variant={ButtonVariant.Secondary}
            />
          </>
        }
      </div>
    </div>
  </div>;
};
