import React, {FunctionComponent, useEffect, useState} from 'react';

import {IBotAction} from 'utils/hooks/use-chat-bot/interfaces';

import Overlay from 'components/overlay';
import {BotAnswer} from '../bot-chat/botAnswer';

interface IXpeditionOverlay {
  answer: BotAnswer
  onChange: (message: IBotAction | null, autoSend?: boolean) => void,
}

export const XpeditionOverlay: FunctionComponent<IXpeditionOverlay> = ({
  answer,
  onChange
}: IXpeditionOverlay) => {
  const [overlays, setOverlays] = useState<IBotAction[]>([]);
  const [lastMessageDate, setLastMessageDate] = useState(new Date());

  useEffect(() => {
    setOverlays(answer.getOverlays() || []);
    setLastMessageDate(new Date(answer.getDate()));
  }, [answer]);

  function handleChangeOverlay(overlayIdx: number) {
    return (actionBody: string, autoSend?: boolean) => {
      if (actionBody.length === 0) {
        onChange(null);

        return;
      }
      onChange({
        ...overlays[overlayIdx],
        actionBody
      }, autoSend);
    };
  }

  return <>
    {
      overlays.map((overlay, overlayIdx) => {
        return <Overlay
          key={overlayIdx}
          overlay={overlay}
          lastMessageDate={lastMessageDate}
          onChange={handleChangeOverlay(overlayIdx)}
        />;
      })
    }
  </>;
};
