import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';

import {IArticleCard} from 'redux/content/interfaces';
import {IStore} from 'redux/interface';

import {maxArticleCards} from 'utils/constants/constants';

import ArticleCard from 'components/article-card';
import Loader from 'components/loader';

import './styles.scss';

type Props = {
  cards: IArticleCard[];
  showLoader?: boolean;
  onLoadMore?: (count: number) => void;
  onClickCard: (cardId: number) => void;
  checkedCards?: number[];
  disabledButtons?: boolean;
}

export const CardSection = ({
  showLoader = false,
  cards,
  onLoadMore,
  onClickCard,
  checkedCards = [],
  disabledButtons = false
}: Props): JSX.Element => {
  const {
    auth: {auth0User},
    content: {page}
  } = useSelector((state: IStore) => state);
  const proFlag = auth0User?.isPro || false;
  const [countCards, setCountCards] = useState(onLoadMore ? maxArticleCards : cards.length);
  const [totalElements, setTotalElements] = useState(0);
  const [cardsList, setCardsList] = useState<IArticleCard[]>(cards);

  useEffect(() => {
    if (page) {
      setTotalElements(page.totalElements);
    }
  }, [page]);

  useEffect(() => {
    setCardsList(cards);
    setCountCards(onLoadMore ? cards.length : maxArticleCards);
  }, [cards]);

  function LoadMore() {
    if (onLoadMore) {
      onLoadMore(countCards / maxArticleCards);
    }
    setCountCards(countCards + maxArticleCards);
  }

  function handleClickCard(id: number) {
    return () => {
      onClickCard(id);
    };
  }

  const buttonLoadMore = totalElements && totalElements > maxArticleCards && totalElements > countCards && onLoadMore
    ? (<button onClick={LoadMore} className="card-section__button-load">
      <FormattedMessage id={'library.sectionCards.load'} defaultMessage={'Load more cards'}/>
    </button>)
    : null;

  return <section className="card-section">
    <ul className="card-section__list">
      {
        cardsList.map((card, i) => {
          return i < countCards
            ? <li key={card.id} className="card-section__item">
              <ArticleCard
                card={card}
                proUser={proFlag}
                checked={checkedCards.includes(card.id)}
                disabledButtons={disabledButtons}
                onClick={handleClickCard(card.id)}/>
            </li> : null;
        })
      }
    </ul>
    <div className="card-section__footer">
      {showLoader ? <Loader/> : buttonLoadMore}
    </div>
  </section>;
};
